<template>
  <div>
    <div>
      <div class="flex items-center w-full pb-4 mb-3 border-b border-gray-300">
        <div class="flex">
          <div class="mr-3" style="width: 13.5rem">
            <div class="block mb-1 text-xs font-bold text-grey-500">Driver</div>
            <FormulateInput
              name="selectedDriver"
              type="driver-multiselect"
              placeholder="Select a driver"
              style="margin-bottom: 0px"
              @selected="onSelectDrivers"
            />
          </div>
          <div class="mr-3" style="width: 14.5rem">
            <label class="block mb-1 text-xs font-bold text-grey-500"
              >Report Date Range</label
            >
            <DatePicker
              ref="childDatePicker"
              :date="selectedDateRange"
              :disabledBefore="disabledBefore"
              :displayEvent="false"
              :displayBreach="false"
              @selected="selectedDateRange = $event"
              range
              noNavigation
              :clearable="false"
              class="send-datepicker"
            />
          </div>
          <div class="mt-5">
            <button
              class="flex items-center btn btn-primary"
              @click.prevent="onSearch(1)"
              style="height: 44.5px"
              :style="{ backgroundColor: theme.secondaryColor }"
            >
              Search
            </button>
          </div>
        </div>
        <!-- <div>
          <button
            :disabled="!selectedDrivers || selectedDrivers.length < 1"
            :class="{
              'cursor-not-allowed':
                !selectedDrivers || selectedDrivers.length < 1,
            }"
            class="flex items-center btn btn-primary"
            @click.prevent="onGetReport"
            style="height: 38px"
            :style="{ backgroundColor: theme.secondaryColor }"
          >
            Get Report
          </button>
        </div> -->
      </div>
      <Table
        :tableHeader="tableHeader"
        :data="eventsTableData"
        :hasActions="true"
        :isLoading="isLoading"
        :paginationSettings="paginationSettings"
        @onPageChange="onPageChange($event)"
      >
        <template #default="{ data }">
          <td class="relative px-5 py-2 text-center">
            <Dropdown>
              <ul class="py-2 text-sm">
                <li>
                  <StyledIconedLink
                    class="block px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-100"
                    @click.prevent="viewEvent(data.id)"
                    :iconHoverColor="theme.secondaryColor"
                  >
                    <font-awesome-icon icon="eye" class="my-auto mr-2" /> View
                  </StyledIconedLink>
                </li>
              </ul>
            </Dropdown>
          </td>
        </template>
      </Table>

      <Modal
        ref="modal"
        :title="modalTitle"
        :height="modalHeight"
        :size="modalSize"
      >
        <div v-if="modalContent == 'view'">
          <EventModificationDetails :eventData="modalData" />
        </div>
      </Modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Table, Dropdown, Modal } from "@/components/commons";
import EventModificationDetails from "@/pages/business/EventModificationDetails.vue";
import { StyledIconedLink } from "@/styled-components";
import { formatPaginationSettings, formatDate } from "@/_helper";
import { EVENT_MODIFICATION_TYPES } from "@/_helper/constants";
import moment from "moment";
import DatePicker from "@/components/commons/field/DatePicker";
import _ from "lodash";

export default {
  name: "Event-Modification",
  components: {
    Table,
    Dropdown,
    Modal,
    EventModificationDetails,
    StyledIconedLink,
    DatePicker,
  },
  data() {
    return {
      disabledBefore: null,
      selectedDrivers: [],
      tableHeader: [
        "Date",
        "Driver Name",
        "Driver Email",
        "Business Name",
        "Business Email",
        "Event Type",
        "Breaches Cleared",
      ],
      eventsTableData: [],
      isLoading: false,
      paginationSettings: {
        page: 1,
        totalPages: 1,
        totalRecords: 0,
        visiblePageItemCount: 10,
      },
      pageLimit: 10,
      modalHeight: "80vh",
      modalContent: false,
      modalTitle: "",
      modalSize: "2xl",
      modalData: null,
      rawData: [],
      selectedDateRange: [],
    };
  },
  async mounted() {
    const eventModificationId = this.$route.params["id"];
    if (eventModificationId) {
      const eventModification = await this.$store.dispatch(
        "business/findEventModificationById",
        eventModificationId
      );
      this.rawData = [eventModification];
      this.paginationSettings = formatPaginationSettings({
        page: 1,
        skip: 0,
        total: 1,
        count: 1,
        limit: 10,
      });
      this.eventsTableData = this.formatTableData([eventModification]);
    } else {
      await this.onSearch();
    }
  },
  methods: {
    async onSearch(page = 1) {
      const me = this.$store.getters["account/me"];
      if (this.canSearch()) {
        this.isLoading = true;

        const fromTime = `${moment(
          this.selectedDateRange[0],
          "YYYY-MM-DD"
        ).format("YYYY-MM-DD")}T00:00:00.000Z`;
        const toTime = `${moment(
          this.selectedDateRange[1],
          "YYYY-MM-DD"
        ).format("YYYY-MM-DD")}T23:59:59.999Z`;

        const query = {
          skip: page * this.pageLimit - this.pageLimit,
          limit: this.pageLimit,
          filter: {
            eventOccurenceDate: {
              $gte: fromTime,
              $lte: toTime,
            },
            // Reads from businessId to get business site id, otherwise use main business id
            businessId: me.businessId ? me.businessId : me.business?._id,
            type: { $in: ['event-edit', 'event-deletion'] }
          },
        };

        if (this.selectedDrivers.length) {
          query.filter = {
            ...query.filter,
            driverId: {
              $in: this.selectedDrivers,
            },
          };
        }
        const rawEventMods = await this.$store.dispatch(
          "business/findEventModifications",
          query
        );

        this.rawData = rawEventMods.data;
        this.paginationSettings = formatPaginationSettings(
          rawEventMods.resultsMeta
        );
        this.eventsTableData = this.formatTableData(rawEventMods.data);
        this.isLoading = false;
      }
    },
    canSearch() {
      return (
        this.selectedDateRange.length > 1 &&
        !_.isEmpty(this.selectedDateRange[0])
      );
    },
    toast(state, msg) {
      const message = {
        state: state,
        message: msg,
      };
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", message);
    },
    onSelectDrivers(drivers) {
      const driverIds = drivers.map((driver) => {
        return driver.driverId;
      });
      this.selectedDrivers = driverIds;
    },
    onPageChange(event) {
      if (event.page) {
        this.onSearch(event.page);
      }
    },
    formatTableData(data) {
      return data.map((x) => {
        return [
          {
            id: x._id,
            name: formatDate(x.eventOccurenceDate),
            itemType: "string",
          },
          {
            id: x._id,
            name: x.driverId.driverName,
            itemType: "string",
          },
          {
            id: x._id,
            name: x.driverId.emailAddress,
            itemType: "string",
          },
          {
            id: x._id,
            name: x.businessId.persona.businessName,
            itemType: "string",
          },
          {
            id: x._id,
            name: x.businessId.supportEmail,
            itemType: "string",
          },
          {
            id: x._id,
            name: EVENT_MODIFICATION_TYPES[x.type],
            itemType: "string",
          },
          {
            id: x._id,
            name: x.isBreachCleared,
            itemType: "string",
          },
        ];
      });
    },
    viewEvent(id) {
      this.$refs.modal.openModal();
      this.modalContent = "view";
      this.modalTitle = "Event Modification";
      this.modalData = this.rawData.find((event) => event._id === id);
    },
  },
  // watch: {
  //   selectedDateRange: {
  //     handler: function () {
  //       this.onSearch()
  //     },
  //     deep: true
  //   }
  // },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
