<template>
  <div>
    <div v-if="readReports">
      <div
        class="flex justify-between w-full pb-4 mb-3 border-b border-gray-300"
      >
        <div class="flex">
          <div class="mr-3" style="width: 13.5rem">
            <div class="block mb-1 text-xs font-bold text-grey-500">Driver</div>
            <FormulateInput
              name="selectedDriver"
              type="driver-multiselect"
              placeholder="Select a driver"
              style="margin-bottom: 0px"
              @selected="onSelectDrivers"
            />
          </div>
          <div class="mr-3" style="width: auto">
            <div class="block mb-1 text-xs font-bold text-grey-500">
              Date of Report
            </div>
            <DatePicker
              ref="childDatePicker"
              :date="selectedDateRange"
              placeholder="YYYY-MM-DD ~ YYYY-MM-DD"
              :disabledBefore="disabledBefore"
              @selected="selectedDateRange = $event"
              range
              noNavigation
              :clearable="false"
            />
          </div>
          <div class="mt-5">
            <button
              :disabled="!selectedDrivers || selectedDrivers.length < 1"
              :class="{
                'cursor-not-allowed':
                  !selectedDrivers || selectedDrivers.length < 1,
              }"
              class="flex items-center btn btn-primary"
              @click.prevent="onSearch"
              style="height: 45px"
              :style="{ backgroundColor: theme.secondaryColor }"
            >
              Search
            </button>
          </div>
        </div>
        <div>
          <div>&nbsp;</div>
          <button
            :disabled="!selectedDrivers || selectedDrivers.length < 1"
            :class="{
              'cursor-not-allowed':
                !selectedDrivers || selectedDrivers.length < 1,
            }"
            class="flex items-center btn btn-primary -mt-1"
            @click.prevent="onGetReport"
            style="height: 44px"
            :style="{ backgroundColor: theme.secondaryColor }"
          >
            Get Report
          </button>
        </div>
      </div>
      <Table
        :tableHeader="tableHeader"
        :data="reportData"
        :hasActions="false"
        :isLoading="isLoading"
        :paginationSettings="paginationSettings"
        @onPageChange="onPageChange($event)"
      ></Table>
    </div>
    <div
      v-if="!readReports && hasRole"
      class="w-full min-h-full mt-5 text-center"
    >
      <h1>
        Not authorized to view reports. Please contact your business admin.
      </h1>
    </div>
    <div v-if="!hasRole" class="text-center">
      <h1>
        You are currently not associated with a role. Please contact support.
      </h1>
    </div>
    <Modal ref="modal" :title="modal.title" size="lg" :height="modal.height">
      <div v-if="modal.content == 'new'">
        <div class="mb-16">
          <div v-if="!sendReportloading">
            <FormulateForm v-model="sendReportModel" @submit="onSubmit">
              <div class="items-center justify-between">
                <div>
                  <div class="block mb-1 text-xs font-bold text-grey-500">
                    Recipient Email
                  </div>
                  <FormulateInput
                    type="email"
                    name="recipientEmail"
                    validation="required|email"
                    @input="handleEmailCasing($event)"
                  />
                </div>
                <div>
                  <div class="block mb-1 text-xs font-bold text-grey-500">
                    Drivers
                  </div>
                  <FormulateInput
                    name="selectedDrivers"
                    type="driver-multiselect"
                    placeholder="Select a driver"
                    @selected="onSendSelectDrivers"
                  />
                </div>
                <div class="pt-2">
                  <div class="block mb-1 text-xs font-bold text-grey-500">
                    Date of Report
                  </div>
                  <DatePicker
                    name="selectedDateRange"
                    ref="childDatePicker"
                    :date="sendReportModel.selectedDateRange"
                    placeholder="YYYY-MM-DD ~ YYYY-MM-DD"
                    :disabledBefore="disabledBefore"
                    @selected="sendReportModel.selectedDateRange = $event"
                    range
                    noNavigation
                    :clearable="false"
                  />
                </div>
                <div class="pt-2">
                  <div class="block mt-3 mb-1 text-xs font-bold text-grey-500">
                    Report Type
                  </div>
                  <FormulateInput
                    name="selectedReportType"
                    type="general-select"
                    element-class="mt-1 mb-2"
                    placeholder="Select a report type"
                    :clearable="false"
                    :options="reportTypes"
                    :reduce="(option) => option.value"
                    validation="required"
                  />
                </div>

                <FormulateInput
                  type="submit"
                  label="Submit"
                  input-class="w-full mt-5 btn btn-primary"
                  :disabled="
                    !sendReportModel.recipientEmail ||
                    sendReportModel.selectedDrivers.length == 0 ||
                    sendReportloading
                  "
                />
              </div>
            </FormulateForm>
          </div>

          <div
            v-else
            class="absolute inset-0 flex flex-col items-center justify-center w-full p-2 text-lg text-center"
          >
            <font-awesome-icon
              icon="cog"
              spin
              size="3x"
              class="text-gray-400"
            />
            <h6 class="mt-4">Sending the report...</h6>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import _ from "lodash";
import moment from "moment";
import DatePicker from "@/components/commons/field/DatePicker";
import { mapGetters } from "vuex";
import { Table, Modal } from "@/components/commons";
import {
  convertJSONToCSV,
  downloadCSV,
  formatPaginationSettings,
} from "@/_helper";
export default {
  name: "Forms-Report-Page",
  components: { DatePicker, Table, Modal },
  data() {
    return {
      reportTypes: ["Time and KM Detailed Report", "Time Sheet CSV"],
      selectedDateRange: [
        moment().format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ],
      readReports: false,
      writeReports: false,
      disabledBefore: null,
      hasRole: true,
      selectedDrivers: [],
      tableHeader: [
        "Driver Name",
        "Driver Email",
        "Total Distance",
        "Total Hours",
        "Unlocked Events Flag",
        "Incorrect Entries Flag",
      ],
      reportData: [],
      isLoading: false,
      paginationSettings: {
        page: 1,
        totalPages: 1,
        totalRecords: 0,
        visiblePageItemCount: 10,
      },
      sendReportModel: {
        selectAll: false,
        recipientEmail: null,
        selectedDrivers: [],
        selectedDateRange: [
          moment().format("YYYY-MM-DD"),
          moment().format("YYYY-MM-DD"),
        ],
        selectedReportType: null,
      },
      sendReportloading: false,
      modal: {
        title: "",
        content: null,
        height: "50vh",
      },
      pageLimit: 10,
      selectAll: false,
      me: null,
    };
  },
  async mounted() {
    this.$emit("updateLoading", true);
    this.me = this.$store.getters[`account/me`];
    this.businessId = this.me.businessId
      ? this.me.businessId
      : this.me?.business?._id;
    let securityRoles = this.me?.securityRoles;
    if (securityRoles && securityRoles.length > 0) {
      const securityAccessObj = await this.setSecurityAccess(securityRoles);
      this.readReports = securityAccessObj.readReports;
    } else {
      this.hasRole = false;
    }

    this.sendReportModel.selectedReportType = this.reportTypes[0];
    this.$emit("updateLoading", false);
  },
  methods: {
    validatePayload() {
      return (
        (this.selectedDrivers || this.selectedDrivers.length > 0) &&
        this.selectedDateRange.length > 1 &&
        !_.isEmpty(this.selectedDateRange[0])
      );
    },
    setSecurityAccess: (securityRoles) => {
      let initialReadReports = false;
      securityRoles.forEach((securityRole) => {
        const securityConfig = securityRole.securityConfig;
        if (securityConfig.reports.readReports?.value) {
          initialReadReports = securityConfig.reports.readReports.value;
        }
      });
      return {
        readReports: initialReadReports,
      };
    },
    async onSearch() {
      const isValidated = this.validatePayload();
      if (!isValidated) return;
      this.isLoading = true;

      const driverStats = await this.getDriverStats();
      this.totalRecordLength = driverStats.summary.length;
      this.totalRecords = driverStats.summary;
      this.handlePagination(driverStats.summary, 1);
      this.isLoading = false;
    },
    async getDriverStats(sendReportModel = null) {
      const isSelectAll = sendReportModel
        ? sendReportModel.selectAll
        : this.selectAll;
      const payload = {
        startDate: sendReportModel
          ? sendReportModel.selectedDateRange[0]
          : this.selectedDateRange[0],
        endDate: sendReportModel
          ? sendReportModel.selectedDateRange[1]
          : this.selectedDateRange[1],
        selectAll: isSelectAll,
        ...(isSelectAll
          ? { businessId: this.businessId }
          : {
              driverIds: sendReportModel
                ? sendReportModel.selectedDrivers
                : this.selectedDrivers,
            }),
      };

      return await this.$store.dispatch("report/getDriverStats", payload);
    },
    onSelectDrivers(drivers, selectAllObject = {}) {
      this.selectAll = !!Object.keys(selectAllObject).length;
      const driverIds = drivers.map((driver) => {
        return driver.driverId;
      });
      this.selectedDrivers = driverIds || [];
    },
    onSendSelectDrivers(drivers, selectAllObject = {}) {
      this.sendReportModel.selectAll = !!Object.keys(selectAllObject).length;
      const driverIds = drivers.map((driver) => {
        return driver.driverId;
      });
      this.sendReportModel.selectedDrivers = driverIds || [];
    },
    formateTableData(data) {
      return data.map((x) => {
        return [
          {
            id: x._id,
            name: x.driverName,
            itemType: "string",
          },
          {
            id: x._id,
            name: x.email,
            itemType: "string",
          },
          {
            id: x._id,
            name: x.totalDistance,
            itemType: "number",
          },
          {
            id: x._id,
            name: x.totalHours,
            itemType: "number",
          },
          {
            id: x._id,
            name: x.unlockedEventFlag,
            itemType: "boolean",
          },
          {
            id: x._id,
            name: x.summaryIncorrectEntryFlag,
            itemType: "boolean",
          },
        ];
      });
    },
    onGetReport() {
      this.$refs.modal.openModal();
      this.modal.content = "new";
      this.modal.title = "Send Report To Email";
    },
    async onSubmit() {
      this.sendReportloading = true;

      if (this.sendReportModel.selectedReportType == this.reportTypes[0]) {
        await this.sendDetailedReport();
      } else {
        await this.downloadCSV();
      }

      this.sendReportloading = false;
    },
    async downloadCSV() {
      const business = this.me.business;
      const isoStringFormat = "YYYY-MM-DDTHH:mm:ssZ";
      const driverStats = await this.getDriverStats(this.sendReportModel);
      const driverStatDetails = driverStats.details;

      let csvString = "Date,Driver,Truck,Event Type,Start Time,End Time\r\n";
      if (driverStatDetails && driverStatDetails.length > 0) {
        let driverStatsJSON = [];

        driverStatDetails.forEach((stats) => {
          let updatedEvents = [];

          // Loop through each event and separate if driving til next day
          stats.events.forEach((event, index) => {
            const nextEvent = stats.events[index + 1];
            if (nextEvent) {
              const nextEventDate = nextEvent.startTime.split("T")[0];
              const currentEventDate = event.startTime.split("T")[0];

              if (
                event.eventType == "work" &&
                currentEventDate != nextEventDate
              ) {
                const timezone = moment.parseZone(event.startTime).utcOffset();
                let clonedEvent = _.cloneDeep(event);
                clonedEvent.startTime = moment(event.startTime, isoStringFormat)
                  .utcOffset(timezone)
                  .add(1, "days")
                  .format("YYYY-MM-DDT00:00:00Z");

                updatedEvents.push(clonedEvent);
              }
            }

            updatedEvents.push(event);
          });

          stats.events = _.sortBy(updatedEvents, ["startTime"]);

          // Loop events again and add to json
          stats.events.forEach((event, index) => {
            const timezone = moment.parseZone(event.startTime).utcOffset();
            const nextEvent = stats.events[index + 1];
            const endTime = nextEvent
              ? moment(nextEvent.startTime, isoStringFormat)
                  .utcOffset(timezone)
                  .format(isoStringFormat)
              : moment(event.startTime, isoStringFormat)
                  .utcOffset(timezone)
                  .format("YYYY-MM-DDT23:59:59Z");
            const currentEventDate = event.startTime.split("T")[0];
            driverStatsJSON.push({
              date: currentEventDate,
              driver: stats.driver.driverName,
              truck: event.vehicle,
              eventType: event.eventType,
              startTime: event.startTime,
              endTime,
            });
          });
        });

        csvString += convertJSONToCSV(
          JSON.parse(JSON.stringify(driverStatsJSON))
        );
        downloadCSV(
          csvString,
          `${business.persona?.businessName
            .toLowerCase()
            .replace(/\s/g, "-")}-time-sheet`
        );
        await this.toast("success", "CSV successfully generated.");
      }
    },
    async sendDetailedReport() {
      const payload = {
        startDate: this.selectedDateRange[0],
        endDate: this.selectedDateRange[1],
        recipientEmail: this.sendReportModel.recipientEmail,
        selectAll: this.selectAll,
        ...(this.selectAll
          ? { entityId: this.businessId }
          : { driverIds: this.selectedDrivers }),
      };

      try {
        await this.$store.dispatch("report/sendDriverStatsReport", payload);
        this.toast(
          "success",
          `An email will be sent to ${payload.recipientEmail} shortly`
        );
      } catch (error) {
        this.toast("error", "Something went wrong!");
      }
    },
    toast(state, msg) {
      const message = {
        state: state,
        message: msg,
      };
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", message);
    },
    handleEmailCasing(value) {
      this.sendReportModel.recipientEmail = value?.toLowerCase();
    },
    handlePagination(totalRecords, page) {
      const startIndex = (page - 1) * this.pageLimit;
      const endIndex = startIndex + this.pageLimit;
      totalRecords = totalRecords.slice(startIndex, endIndex);
      const formattedReportData = this.formateTableData(totalRecords);
      const paginationMetaData = {
        skip: page * this.pageLimit - this.pageLimit,
        limit: this.pageLimit,
        total: this.totalRecordLength,
        count:
          formattedReportData.length % this.pageLimit === 0
            ? this.pageLimit
            : formattedReportData.length % this.pageLimit,
      };
      this.paginationSettings = formatPaginationSettings(paginationMetaData);
      this.reportData = formattedReportData;
    },
    async onPageChange(event) {
      if (event.page) {
        this.handlePagination(this.totalRecords, event.page);
      }
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
<style scoped lang="scss">
.compliance-container {
  min-height: calc(100vh - 92px);
}
.send-datepicker::v-deep > div {
  @apply w-full;
}
</style>
