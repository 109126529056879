<template>
    <div class="login-container max-w-md mx-auto">
      <div class="rounded-lg bg-gray-100 p-10">
        <NewDriverAbort :driverId="driverId"></NewDriverAbort>
      </div>
    </div>
</template>

<script>
import { NewDriverAbort } from '@/components/forms/driver'

export default{
  name:"Solo-Driver-Accept",
  components:{
    NewDriverAbort
  },
  data(){
    return {
      loading:true,
      driverId:null
    }
  },
  async mounted(){
    this.loading = true;
    this.driverId = this.$route.params['driverId'];
    try{
      const currentDriver = await this.$store.dispatch(`driver/getDriver`, {id: this.driverId});
      if(currentDriver.uid){
        window.location.href = '/404?message=Invalid request'
      }
      this.loading = false;
    }catch{
      window.location.href = '/404?message=Invalid request'
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
