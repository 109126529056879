<template>
  <div class="pb-12">
    <div class="items-center">
      <label class="text-sm font-semibold text-neutral-dark">Recipients</label>
      <div class="flex flex-row mb-2">
        <div class="w-2/3">
          <input
            class="shadow-sm border border-gray-400 rounded px-3 py-2 leading-none focus:border-orange-primary focus:shadow outline-none w-full"
            v-model="newRecipient"
            placeholder="Add recipient"
            @focus="clearValidation"
            ref="recipient"
          />
        </div>
        <div class="w-1/3 text-right text-xs">
          <button
            class="btn btn-primary"
            @click.prevent="addRecipient()"
            :style="{ backgroundColor: theme.secondaryColor, height:'100%' }"
          >
            Add Email
          </button>
        </div>
      </div>

      <div
        class="py-2 border rounded-lg w-full"
        v-if="selectedRecipients && selectedRecipients.length > 0"
      >
        <div class="flex items-center flex-wrap px-2">
          <small
            v-for="(data, index) in selectedRecipients"
            class="text-white px-4 py-2 mx-1 my-1 rounded-full"
            :style="{ backgroundColor: theme.primaryColor }"
            :key="index"
          >
            {{ data
            }}<button
              @click.prevent="removeRecipient(data)"
              class="ml-2 mr-1"
            >
              <b>x</b>
            </button>
          </small>
        </div>
      </div>

      <div class="text-red-500 text-center mb-3" v-if="validationError">
        {{ validationError }}
      </div>

      <div class="-mb-2 mt-5">
        <FormulateInput
          type="select"
          label="Interval Type"
          name="intervalTyp1e"
          :value="intervalType"
          :options="intervalOptions"
          @change="intervalOnChange($event)"
        />
      </div>

      <div v-if="intervalType === 'WEEKLY'">
        <DayIntervalForm
          :editData="editData"
          :selectedEntityType="selectedEntityType"
          :isKmTimeModuleFound="isKmTimeModuleFound"
          :driverId="driverId"
          @submit="onSubmitSchedule"
        />
      </div>
      <div v-if="intervalType === 'MONTHLY'">
        <DateIntervalForm
          :editData="editData"
          :selectedEntityType="selectedEntityType"
          :isKmTimeModuleFound="isKmTimeModuleFound"
          :driverId="driverId"
          @submit="onSubmitSchedule"
        />
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { BUSINESS_REPORT_OPTIONS, DRIVER_REPORT_OPTIONS, REPORT_NAMES, } from "@/_helper/constants";
import DayIntervalForm from "@/components/forms/scheduling/day-interval.vue";
import DateIntervalForm from "./scheduling/date-interval.vue";
import { mapGetters } from "vuex";

export default {
  name: "ReportSchedulingForm",
  components: { DayIntervalForm, DateIntervalForm },
  props: {
    editData: {
      type: Object,
      default: () => ({}),
    },
    selectedEntityType: {
      type: String,
    },
    isKmTimeModuleFound: {
      type: Boolean,
    },
    driverId: {
      type: String,
    },
    submitType: {
      type: String,
      default: "create",
    },
  },
  data() {
    return {
      createScheduleLoading: false,
      createScheduleModel: {
        reportDurationInDays: 7,
        reportName: REPORT_NAMES.COMPLIANCE_REPORT,
      },
      reportOptions: [],
      reportNames: REPORT_NAMES,
      isDriverStatsReportSelected: false,
      businessReportOptions: BUSINESS_REPORT_OPTIONS,
      driverReportOptions: DRIVER_REPORT_OPTIONS,
      selectedDrivers: [],
      selectedRecipients: [],
      alreadyAssignedAttendees: [],
      newRecipient: "",
      validationError: "",
      intervalType: "WEEKLY",
      intervalOptions: [
        {
          label: "Monthly",
          value: "MONTHLY",
        },
        {
          label: "Weekly",
          value: "WEEKLY",
        },
      ],
    };
  },
  destroyed() {
    this.$emit("closeModal");
  },
  async mounted() {
    switch (this.selectedEntityType) {
      case "business":
        if (!this.isKmTimeModuleFound) {
          this.businessReportOptions = this.businessReportOptions.filter(
            (report) => {
              return report.value !== this.reportNames.DRIVER_STATS_REPORT;
            }
          );
        }
        this.reportOptions = this.businessReportOptions;
        break;
      case "driver":
        this.reportOptions = this.driverReportOptions;
        break;
      default:
        this.reportOptions = [];
    }

    if (!_.isEmpty(this.editData)) {
      const parsedData = JSON.parse(JSON.stringify(this.editData));
      if (this.isArray(parsedData.recipient)) {
        this.selectedRecipients = [...parsedData.recipient];
      } else {
        this.selectedRecipients = [parsedData.recipient];
      }
      if (this.submitType === "edit") {
        this.selectedDrivers = this.editData.metadata.driverIds;
        this.intervalType = this.editData?.interval;
      }
    }
  },
  created() {
    if (!_.isEmpty(this.editData) && this.selectedEntityType !== "driver") {
      this.selectedDrivers = this.editData.metadata.driverIds;
      this.submitType = "edit";

      this.intervalType = this.editData?.interval;
    }
  },
  methods: {
    onSubmitSchedule(data) {
      if (this.selectedRecipients.length === 0) {
        this.validationError = "No Recipients Selected";
        return;
      }

      if (this.submitType === "edit") {
        const parsedEditData = _.omit(this.editData, [
          "_id",
          "createdAt",
          "updatedAt",
          "__v",
          "lastExecutionDate",
          "userType",
          "nextExecutionDate",
          "userMongoId",
          "isActive",
          "reportName",
        ]);

        data.recipient = JSON.parse(JSON.stringify(this.selectedRecipients));

        parsedEditData.metadata = JSON.parse(
          JSON.stringify(parsedEditData.metadata)
        );

        const diff = this.getObjectDiff(parsedEditData, data);

        data._id = this.editData._id;

        if (diff && diff.length === 0) {
          this.validationError = "No new changes";
          return;
        }
        this.$emit("submit", data, this.submitType);
        return;
      }

      data.recipient = JSON.parse(JSON.stringify(this.selectedRecipients));
      this.$emit("submit", data, this.submitType);
    },

    getObjectDiff(obj1, obj2) {
      return Object.keys(obj1).reduce((result, key) => {
        // eslint-disable-next-line no-prototype-builtins
        if (!obj2.hasOwnProperty(key)) {
          result.push(key);
        } else if (_.isEqual(obj1[key], obj2[key])) {
          const resultKeyIndex = result.indexOf(key);
          result.splice(resultKeyIndex, 1);
        }
        return result;
      }, Object.keys(obj2));
    },

    isArray(obj) {
      return Object.prototype.toString.call(obj) === "[object Array]";
    },

    addRecipient(data) {
      this.$refs.recipient.blur();
      let name = this.newRecipient;
      let existing = this?.selectedRecipients?.filter((item) => item === name);

      // eslint-disable-next-line no-useless-escape
      const isEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(name);

      if (isEmail) {
        if (_.isEmpty(existing)) {
          this.selectedRecipients.push(name);
          this.alreadyAssignedAttendees.push(data);
        }
        this.newRecipient = "";
        this.createScheduleModel.attendees = this.selectedRecipients;
      } else {
        this.validationError = "Invalid Recipient";
        this.newRecipient = "";
      }
    },
    removeRecipient(user) {
      this.selectedRecipients.splice(this.selectedRecipients.indexOf(user), 1);
      this.alreadyAssignedAttendees.splice(
        this.alreadyAssignedAttendees.indexOf(user),
        1
      );
    },

    clearValidation() {
      if (this.validationError !== "") {
        this.validationError = "";
      }
    },

    intervalOnChange(event) {
      if (!event) return;

      this.intervalType = event.target.value;
    },
  },

  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>

<style scoped></style>
