<template>
  <div class="w-full overflow-y-auto border border-gray-200 rounded">
    <table class="min-w-full">
      <thead class="bg-gray-50">
        <tr class="text-xs tracking-wider text-gray-700 uppercase">
          <th scope="col" class="px-5 py-3 text-center">Activity</th>
          <th scope="col" class="px-5 py-3 text-center">
            Time of<br />Activity
          </th>
          <th scope="col" class="px-5 py-3 text-left">Location</th>
          <th v-if="isEnhancedReport" scope="col" class="px-5 py-3 text-left">
            Latitude and<br />Longitude
          </th>
          <th scope="col" class="px-5 py-3 text-center">Odometer</th>
          <th scope="col" class="px-5 py-3 text-center">Vehicle</th>
          <th
            scope="col"
            class="px-5 py-3 text-center"
            style="min-width: 130px"
          >
            Work and<br />Rest Option
          </th>
          <th scope="col" class="px-5 py-3 text-left">Comments</th>
          <th scope="col" class="px-5 py-3 text-center">Origin</th>
          <th scope="col" class="px-5 py-3 text-left">Entry Timestamp</th>
          <th scope="col" class="px-5 py-3 text-left">Status</th>
          <th scope="col" class="px-5 py-3 text-left">Tampered</th>
          <th scope="col" class="px-5 py-3 text-left">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-if="eventsData.length == 0"
          class="text-xs font-medium text-gray-500 bg-white border-b"
        >
          <td colspan="12" class="px-5 py-3 text-sm text-center">
            No events this day
          </td>
        </tr>
        <template v-for="(event, index) in eventsData">
          <tr
            :id="'row-' + index"
            :key="'row-' + index"
            class="text-xs font-medium text-gray-500 bg-white border-t"
          >
            <td class="px-5 py-3 text-center capitalize text-black-primary">
              {{ event.type }}
            </td>
            <td class="px-5 py-3 text-center">
              {{ event.timeOfActivity }}
            </td>
            <td class="px-5 py-3">
              {{ event.locationAddress }}
            </td>
            <td v-if="isEnhancedReport" class="px-5 py-3">
              <div class="flex items-center">
                <div
                  @click="copyToClipboard(index, `${event.latitude},${event.longitude}`)"
                  class="relative text-center cursor-pointer gps-location"
                >
                  {{ event.latitude }}<br />{{ event.longitude }}
                  <div
                    class="absolute inset-0 items-center justify-center hidden h-6 text-xs leading-none text-white rounded opacity-80"
                    :style="{
                      backgroundColor:
                        copiedIndex === index ? '#68D391' : 'rgb(40, 115, 165)',
                      fontSize: '9px',
                    }"
                  >
                    {{ copiedIndex === index ? "Copied" : "Click to copy" }}
                  </div>
                </div>
              </div>
            </td>
            <td class="px-5 py-3 text-center">
              {{ event.odometer }}
            </td>
            <td class="px-5 py-3 text-center">
              {{ event.vehicle }}
            </td>
            <td class="px-5 py-3 text-center uppercase">
              {{ event.fatiguePlan }}
            </td>
            <td class="px-5 py-3">
              {{ event.comment }}
            </td>
            <td class="px-5 py-3 text-center capitalize">
              {{ event.origin }}
            </td>
            <td class="px-5 py-3">
              <div class="flex items-center">
                <div
                  @click="copyToClipboard(index, `${event.originalStartTime}`)"
                  class="relative text-center cursor-pointer gps-location"
                >
                  {{ event.originalStartTime }}
                  <div
                    class="absolute inset-0 items-center justify-center hidden h-6 text-xs leading-none text-white rounded opacity-80"
                    :style="{
                      backgroundColor:
                        copiedIndex === index ? '#68D391' : 'rgb(40, 115, 165)',
                      fontSize: '9px',
                    }"
                  >
                    {{ copiedIndex === index ? "Copied" : "Click to copy" }}
                  </div>
                </div>
              </div>
            </td>
            <td class="px-5 py-3 uppercase">
              {{ event.status }}
            </td>
            <td class="px-5 py-3 text-center">
              {{ event.tampered }}
            </td>
            <td class="px-5 py-3 text-center">
              <button
                v-if="event.changes.length > 0"
                :id="'row-button-' + index"
                class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-gray-700 bg-gray-200 rounded"
                @click.capture="toggleDropdown(index)"
                type="button"
              >
                <font-awesome-icon
                  icon="chevron-right"
                  class="toggle-icon-right"
                />
                <font-awesome-icon
                  icon="chevron-down"
                  class="hidden toggle-icon-down"
                />
              </button>
            </td>
          </tr>
          <tr
            :id="'row-hidden-' + index"
            :key="'row-hidden-' + index"
            class="hidden text-xs font-medium text-gray-500 bg-white"
          >
            <td colspan="12">
              <div class="px-5 pb-5">
                <table class="min-w-full border border-gray-200">
                  <thead class="border-b border-gray-200 bg-gray-50">
                    <th scope="col" class="px-5 py-3 text-left">
                      Edited Field
                    </th>
                    <th scope="col" class="px-5 py-3 text-left">Old Value</th>
                    <th scope="col" class="px-5 py-3 text-left">New Value</th>
                    <th scope="col" class="px-5 py-3 text-left">Timestamp</th>
                  </thead>
                  <tbody>
                    <tr
                      v-if="event.changes.length == 0"
                      class="text-xs font-medium text-gray-500 border-t"
                    >
                      <td colspan="3" class="px-5 py-3 text-sm text-center">
                        No event changes
                      </td>
                    </tr>
                    <tr
                      v-else
                      v-for="(change, changeIndex) in event.changes"
                      :key="changeIndex"
                      class="border-t"
                    >
                      <td class="px-5 py-3 capitalize">
                        {{ change.field }}
                      </td>
                      <td class="px-5 py-3">
                        {{ change.from }}
                      </td>
                      <td class="px-5 py-3">
                        {{ change.to }}
                      </td>
                      <td class="px-5 py-3">
                        {{ change.timestamp }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>
<script>
import moment from "moment";
import _ from "lodash";
export default {
  name: "Event",
  props: {
    data: Array,
    isEnhancedReport: Boolean,
  },
  data() {
    return {
      events: [],
      copiedIndex: null,
    };
  },
  mounted() {
    this.events = this.data;
  },
  methods: {
    toggleDropdown(index) {
      const button = document.getElementById("row-button-" + index);
      const hiddenElement = document.getElementById("row-hidden-" + index);
      if (hiddenElement.classList.contains("hidden")) {
        hiddenElement.classList.remove("hidden");
        button.querySelector(".toggle-icon-down").classList.remove("hidden");
        button.querySelector(".toggle-icon-right").classList.add("hidden");
      } else {
        hiddenElement.classList.add("hidden");
        button.querySelector(".toggle-icon-right").classList.remove("hidden");
        button.querySelector(".toggle-icon-down").classList.add("hidden");
      }
    },
    async copyToClipboard(index, text) {
      await navigator.clipboard.writeText(text);
      this.copiedIndex = index;

      setTimeout(() => {
        this.copiedIndex = null;
      }, 2000);
    }
  },
  watch: {
    data: {
      handler: function (newValue) {
        this.events = newValue;
      },
      deep: true,
    },
  },
  filters: {
    formatDate(value, format) {
      return moment(value).format(format);
    },
  },
  computed: {
    eventsData() {
      this.events.forEach((event) => {
        const timezone = event.timezone.split(" ")[1];
        event['createdAt'] = moment(event['createdAt']).utcOffset(timezone).format("YYYY-MM-DDTHH:mm:ssZ")

        event?.changes?.forEach((change) => {
          if (change.field == "startTime") {
            change.field = "Time of activity";
          }

          change["timestamp"] =
            typeof change.date == "object"
              ? moment
                  .unix(change.date._seconds)
                  .utcOffset(timezone)
                  .format("YYYY-MM-DDTHH:mm:ssZ")
              : moment(change.date)
                  .utcOffset(timezone)
                  .format("YYYY-MM-DDTHH:mm:ssZ");
        });

        // Sorting the data by timestamp
        event.changes = _.sortBy(event.changes, "timestamp").reverse();
      });

      return this.events || [];
    },
  },
};
</script>
<style scoped lang="scss">
.gps-location:hover div {
  display: flex !important;
}
</style>
