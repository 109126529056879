<template>
  <div id="map" ref="mapContainer"></div>
</template>

<script>
import { capitalizeFirstLetter } from "@/_helper";
import moment from "moment";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { formatDate } from "../../../../_helper";
mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_TOKEN;

export default {
  name: "Map",
  components: {},
  props: {
    events: Array,
    date: String,
  },
  data() {
    return {
      usedVehicles: [],
      trips: [],
      sydneyCoordinates: [151.2093, -33.8688],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      this.loading = true;
      const usedVehiclesObject = this.events.reduce((acc, current) => {
        const { vehicle, startTimeWithTimezone, startTime } = current;
        if (acc[vehicle]) {
          acc[vehicle].endTimeWithTimezone = startTimeWithTimezone;
          acc[vehicle].endTime = startTime;
        } else {
          acc[vehicle] = {
            startTime,
            endTime: startTime,
            startTimeWithTimezone,
            endTimeWithTimezone: startTimeWithTimezone,
          };
        }
        return acc;
      }, {});

      /**
       * this.usedVehicles = [
       *  {
       *    "vehicle": "ABC123",
       *    "startTime": "2024-01-01",
       *    "endTime": "2024-01-02",
       *    "startTimeWithTimezone": "2024-01-02",
       *    "endTimeWithTimezone": "2024-01-02",
       *
       *  }
       * ]
       */
      this.usedVehicles = Object.entries(usedVehiclesObject).map(
        ([key, value]) => {
          return {
            ...value,
            vehicle: key,
          };
        }
      );

      if (!this.usedVehicles?.length) {
        this.initializeMap();
        return;
      }

      const [event] = this.events;
      const { driverId } = event;
      const fleetProviderTripsPayload = {
        date: this.date,
        vehicles: this.usedVehicles,
        driverId,
      };

      this.trips = await this.$store.dispatch(
        `business/findFleetDriverTrips`,
        fleetProviderTripsPayload
      );

      this.initializeMap();
    },
    initializeMap() {
      const centerPoint = !this.trips.length
        ? this.sydneyCoordinates
        : [
            this.trips[0].metadata[0].coordinates[0],
            this.trips[0].metadata[0].coordinates[1],
          ];
      const map = new mapboxgl.Map({
        container: this.$refs.mapContainer,
        style: "mapbox://styles/mapbox/streets-v12",
        center: centerPoint,
        zoom: 10,
      });

      map.on("load", () => {
        this.trips.forEach((trip, index) => {
          const { tripStartTime, tripEndTime, color, metadata, provider } =
            trip;
          const layerId = `Trip #${index + 1}`;
          map.addLayer({
            id: layerId,
            type: "line",
            source: {
              type: "geojson",
              data: {
                type: "Feature",
                geometry: {
                  type: "LineString",
                  coordinates: metadata.map(
                    (tripMetadata) => tripMetadata.coordinates
                  ),
                },
                properties: {
                  description: `
                  <div>
                    <ul>
                      <li style="display:flex; align-items:center">
                        <div class="color-circle" style="background-color: ${color};">
                        </div>
                        <div style="margin-left:5px;">
                          <b>${layerId}</b>
                        </div>
                      </li>
                      <li>
                        <b>Fleet Provider</b> ${provider}
                      </li>
                      <li>
                        <b>Trip Start Time</b> ${formatDate(
                          tripStartTime,
                          undefined,
                          undefined,
                          true
                        )}
                      </li>
                       <li>
                        <b>Trip End Time</b> ${formatDate(
                          tripEndTime,
                          undefined,
                          undefined,
                          true
                        )}
                      </li>
                    </ul>
                  </div>`,
                },
              },
            },
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color": color,
              "line-width": 5,
            },
          });

          map.on("mouseenter", layerId, () => {
            map.getCanvas().style.cursor = "pointer";
          });
          map.on("mouseleave", layerId, () => {
            map.getCanvas().style.cursor = "";
          });
          let popup = null;
          map.on("click", layerId, (e) => {
            // If any other popup is opened, close it.
            if (popup) {
              popup.remove();
            }
            const description = e.features[0].properties.description;
            const coordinates = e.lngLat;
            popup = new mapboxgl.Popup({
              closeButton: false,
              closeOnClick: false,
            })
              .setLngLat(coordinates)
              .setHTML(description)
              .addTo(map);
          });

          // Listening to clicks outside the layer
          map.on("click", (e) => {
            // Check if the click was on the target layer
            const features = map.queryRenderedFeatures(e.point, {
              layers: [layerId],
            });

            // If the click was outside the layer and there's an open popup, close it
            if (popup && features.length === 0) {
              popup.remove();
              popup = null;
            }
          });
        });

        this.events.forEach((event) => {
          const {
            fleetProviderLocation,
            type,
            startTimeWithTimezone,
            offset,
            location,
            odometer,
            originalStartTime,
            vehicle,
          } = event;
          const formattedTimeOfActivity = moment(startTimeWithTimezone)
            .utcOffset(offset)
            .format("HH:mm");
          if (fleetProviderLocation) {
            const popup = new mapboxgl.Popup({}).setHTML(
              `<div>
                <ul>
                  <li><b>Event Type:</b> ${capitalizeFirstLetter(type)}</li>
                  <li><b>Time of Activity:</b> ${formattedTimeOfActivity}</li>
                  <li><b>Location:</b> ${location?.address || "N/A"}</li>
                  <li><b>Odometer:</b> ${odometer}</li>
                  <li><b>Vehicle:</b> ${vehicle}</li>
                  <li><b>Entry Timestamp:</b> ${originalStartTime}</li>
                </ul>
              </div>`
            );
            new mapboxgl.Marker({
              rotation: type === "work" ? 0 : 180,
              color: type === "work" ? "black" : "",
            })
              .setLngLat([
                fleetProviderLocation.longitude,
                fleetProviderLocation.latitude,
              ])
              .setPopup(popup)
              .addTo(map);
          }
        });
      });
    },
  },
};
</script>
<style>
#map {
  width: 100%;
  height: 100%;
  position: relative;
}
.mapboxgl-popup-content {
  background-color: white; /* White background */
  border-radius: 8px; /* Rounded corners */
  padding: 10px; /* Padding inside the popup */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2); /* Add some shadow for depth */
  display: inline-block;
}

.color-circle {
  width: 20px; /* Width of the circle */
  height: 20px; /* Height of the circle */
  border-radius: 50%; /* Makes the div a circle */
  display: inline-block; /* Ensures the circle is inline with the text */
}
</style>
