<template>
  <div>
    <div v-if="readDrivers" class="flex flex-col h-full space-y-4">
      <Tabs
        clickEvent
        @click="fetchTab"
        :activeTab="0"
        class="flex flex-col h-full"
      >
        <Tab title="Linked" class="flex flex-col flex-1">
          <Table
            :isLoading="isLoading"
            :tableHeader="tableHeaderWithTraining"
            :paginationSettings="paginationSettings"
            :data="driversData"
            @onPageChange="onPageChange($event)"
            :has-multi-filter="true"
            @onMultiFilter="onMultiFilter"
          >
            <template #tableFilters>
              <div class="flex flex-row mb-1 sm:mb-0">
                <div class="relative">
                  <select
                    v-model="filter.limit"
                    @change="filterChange"
                    class="block w-full h-full px-4 py-2 pr-8 leading-tight text-gray-700 bg-white border border-gray-400 rounded-l appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                  >
                    <option :value="10">10</option>
                    <option :value="20">20</option>
                    <option :value="50">50</option>
                  </select>
                  <div
                    class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none"
                  >
                    <font-awesome-icon
                      icon="caret-down"
                      class="text-gray-400"
                    />
                  </div>
                </div>
              </div>
              <div class="relative block">
                <span
                  class="absolute inset-y-0 left-0 flex items-center h-full pl-2"
                >
                  <font-awesome-icon icon="search" class="text-gray-400" />
                </span>
                <input
                  placeholder="Search"
                  class="h-[38px] appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                  v-model="filter.search"
                  @keydown="handleSearchKeyDown"
                />
              </div>
            </template>
            <template #default="{ data }">
              <td class="px-5 py-2">
                <div class="text-lg whitespace-no-wrap text-black-primary">
                  <StyledIconedLink
                    class="flex justify-center cursor-pointer"
                    @click.prevent="showTrainingStatus(data.driverId)"
                    :iconColor="data.trainingStatus"
                    :iconHoverColor="theme.secondaryColor"
                  >
                    <font-awesome-icon icon="circle" class="my-auto" />
                  </StyledIconedLink>
                </div>
              </td>
              <td class="relative px-5 py-2 text-center">
                <Dropdown ref="dropdown">
                  <ul class="py-2 text-sm">
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-100"
                        @click.prevent="viewDriver(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="eye" class="my-auto mr-2" />
                        View
                      </StyledIconedLink>
                    </li>
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-100"
                        @click.prevent="editDriver(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="edit" class="my-auto mr-2" />
                        Edit
                      </StyledIconedLink>
                    </li>
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-100"
                        @click.prevent="delinkDriver(data.driverId, businessId)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="unlink" class="my-auto mr-2" />
                        Delink
                      </StyledIconedLink>
                    </li>
                    <li>
                      <StyledIconedLink
                        v-if="data.showAddProfileOption"
                        class="block px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-100"
                        @click.prevent="createNewProfile(data.driverId)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="plus" class="my-auto mr-2" />
                        Add New Profile
                      </StyledIconedLink>
                    </li>
                  </ul>
                  <div class="py-2">
                    <StyledIconedLink
                      class="block px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-100"
                      @click.prevent="resetDriverPassword(data.email)"
                      :iconHoverColor="theme.secondaryColor"
                    >
                      <font-awesome-icon icon="undo" class="my-auto mr-2" />
                      Reset Password
                    </StyledIconedLink>
                  </div>
                </Dropdown>
              </td>
            </template>
            <template v-if="writeDrivers" #callToActionTop>
              <div class="flex flex-row mb-1 space-x-1 sm:mb-0">
                <button
                  class="btn btn-primary"
                  @click.prevent="downloadDrivers()"
                  :style="{ backgroundColor: theme.secondaryColor }"
                  title="Download All Drivers"
                >
                  <font-awesome-icon icon="download" class="my-auto" />
                </button>
                <button
                  class="btn btn-primary"
                  @click.prevent="newEwdDriverImport()"
                  :style="{ backgroundColor: theme.secondaryColor }"
                >
                  Import EWD Driver
                </button>
                <button
                  class="btn btn-primary"
                  @click.prevent="newDriver()"
                  :style="{ backgroundColor: theme.secondaryColor }"
                >
                  Add / Link Driver
                </button>
              </div>
            </template>
            <template v-if="writeDrivers" #informationalPanel>
                <div class="flex items-center px-3 py-2 mt-1 text-sm bg-white shadow">
                  <font-awesome-icon
                    icon="info-circle"
                    size="lg"
                    class="mr-2 text-blue"
                  />
                  <span>This section displays and creates driver accounts that are paid by the business subscription.</span>
                </div>
                <div
                  v-if="isDriverContractTarget"
                  class="p-3 mt-2 bg-white shadow"
                  role="alert"
                >
                  <div :style="{ color: theme.primaryColor }" class="text-sm font-bold text-italic">
                    Available Seats: <span :style="{ color: theme.secondaryColor }">{{ totalAvailableSeat.ewd }}</span> remaining of {{ maximumSeatCount.ewd.count }} subscribed.
                  </div>
                  <div class="mt-1 text-xs">
                    <router-link
                      to="/business/billing"
                      class="hover:bg-logmaster-400-light"
                    >
                      <span
                        class="underline"
                        :style="{ color: theme.secondaryColor }"
                        >See billing section</span
                      >
                    </router-link> to adjust seat total
                  </div>
                </div>
            </template>
          </Table>
        </Tab>
        <Tab title="Pending" class="flex flex-col flex-1">
          <Table
            :isLoading="isLoading"
            :tableHeader="tableHeader"
            :paginationSettings="paginationSettings"
            :data="driversData"
            @onPageChange="onPageChange($event)"
            :has-multi-filter="true"
            @onMultiFilter="onMultiFilter"
          >
            <template #tableFilters>
              <div class="flex flex-row mb-1 sm:mb-0">
                <div class="relative">
                  <select
                    v-model="filter.limit"
                    @change="filterChange"
                    class="block w-full h-full px-4 py-2 pr-8 leading-tight text-gray-700 bg-white border border-gray-400 rounded-l appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                  >
                    <option :value="10">10</option>
                    <option :value="20">20</option>
                    <option :value="50">50</option>
                  </select>
                  <div
                    class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none"
                  >
                    <font-awesome-icon
                      icon="caret-down"
                      class="text-gray-400"
                    />
                  </div>
                </div>
              </div>
              <div class="relative block">
                <span
                  class="absolute inset-y-0 left-0 flex items-center h-full pl-2"
                >
                  <font-awesome-icon icon="search" class="text-gray-400" />
                </span>
                <input
                  placeholder="Search"
                  class="h-[38px] appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                  v-model="filter.search"
                  @keydown="handleSearchKeyDown"
                />
              </div>
            </template>
            <template #default="{ data }">
              <td class="relative px-5 py-2 text-center">
                <Dropdown ref="dropdown">
                  <ul class="py-2 text-sm">
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-100"
                        @click.prevent="viewDriver(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="eye" class="my-auto mr-2" />
                        View
                      </StyledIconedLink>
                    </li>
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-100"
                        @click.prevent="editDriver(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="edit" class="my-auto mr-2" />
                        Edit
                      </StyledIconedLink>
                    </li>
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-100"
                        @click.prevent="resendInvite(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon
                          icon="paper-plane"
                          class="my-auto mr-2"
                        />
                        Resend Invite
                      </StyledIconedLink>
                    </li>
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-100"
                        @click.prevent="rescindInvite(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="times" class="my-auto mr-2" />
                        Rescind Invite
                      </StyledIconedLink>
                    </li>
                  </ul>
                  <div class="py-2">
                    <StyledIconedLink
                      class="block px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-100"
                      @click.prevent="editDriverEmail(data.id)"
                      :iconHoverColor="theme.secondaryColor"
                    >
                      <font-awesome-icon icon="envelope" class="my-auto mr-2" />
                      Change Email
                    </StyledIconedLink>
                  </div>
                </Dropdown>
              </td>
            </template>
            <template v-if="writeDrivers" #callToActionTop>
              <div class="flex flex-row mb-1 space-x-1 sm:mb-0">
                <button
                  class="btn btn-primary"
                  @click.prevent="resendInvitesToAll()"
                  :style="{ backgroundColor: theme.secondaryColor }"
                >
                  Resend Invites To All
                </button>
              </div>
            </template>
            <template v-if="writeDrivers" #informationalPanel>
                <div
                  v-if="isDriverContractTarget"
                  class="p-3 mt-3 bg-white shadow"
                  role="alert"
                >
                  <div :style="{ color: theme.primaryColor }" class="text-sm font-bold text-italic">
                    Available Seats: <span :style="{ color: theme.secondaryColor }">{{ totalAvailableSeat.ewd }}</span> remaining of {{ maximumSeatCount.ewd.count }} subscribed.
                  </div>
                  <div class="mt-1 text-xs">
                    <router-link
                      to="/business/billing"
                      class="hover:bg-logmaster-400-light"
                    >
                      <span
                        class="underline"
                        :style="{ color: theme.secondaryColor }"
                        >See billing section</span
                      >
                    </router-link> to adjust seat total
                  </div>
                </div>
            </template>
          </Table>
        </Tab>
        <Tab title="Delinked" class="flex flex-col flex-1">
          <Table
            :isLoading="isLoading"
            :tableHeader="tableHeader"
            :paginationSettings="paginationSettings"
            :data="driversData"
            @onPageChange="onPageChange($event)"
            :has-multi-filter="true"
            @onMultiFilter="onMultiFilter"
          >
            <template #tableFilters>
              <div class="flex flex-row mb-1 sm:mb-0">
                <div class="relative">
                  <select
                    v-model="filter.limit"
                    @change="filterChange"
                    class="block w-full h-full px-4 py-2 pr-8 leading-tight text-gray-700 bg-white border border-gray-400 rounded-l appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                  >
                    <option :value="10">10</option>
                    <option :value="20">20</option>
                    <option :value="50">50</option>
                  </select>
                  <div
                    class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none"
                  >
                    <font-awesome-icon
                      icon="caret-down"
                      class="text-gray-400"
                    />
                  </div>
                </div>
              </div>
              <div class="relative block">
                <span
                  class="absolute inset-y-0 left-0 flex items-center h-full pl-2"
                >
                  <font-awesome-icon icon="search" class="text-gray-400" />
                </span>
                <input
                  placeholder="Search"
                  class="h-[38px] appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                  v-model="filter.search"
                  @keydown="handleSearchKeyDown"
                />
              </div>
            </template>
            <template #default="{ data }">
              <td class="relative px-5 py-2 text-center">
                <Dropdown ref="dropdown">
                  <ul class="py-2 text-sm">
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-100"
                        @click.prevent="viewDriver(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="eye" class="my-auto mr-2" />
                        View
                      </StyledIconedLink>
                    </li>
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-100"
                        @click.prevent="relinkDriver(data.driverId)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="link" class="my-auto mr-2" />
                        Relink
                      </StyledIconedLink>
                    </li>
                  </ul>
                </Dropdown>
              </td>
            </template>
          </Table>
        </Tab>
        <Tab title="Self-paid" class="flex flex-col flex-1">
          <Table
            :isLoading="isLoading"
            :tableHeader="tableHeaderWithTraining"
            :paginationSettings="paginationSettings"
            :data="driversData"
            @onPageChange="onPageChange($event)"
            :has-multi-filter="true"
            @onMultiFilter="onMultiFilter"
          >
            <template #tableFilters>
              <div class="flex flex-row mb-1 sm:mb-0">
                <div class="relative">
                  <select
                    v-model="filter.limit"
                    @change="filterChange"
                    class="block w-full h-full px-4 py-2 pr-8 leading-tight text-gray-700 bg-white border border-gray-400 rounded-l appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                  >
                    <option :value="10">10</option>
                    <option :value="20">20</option>
                    <option :value="50">50</option>
                  </select>
                  <div
                    class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none"
                  >
                    <font-awesome-icon
                      icon="caret-down"
                      class="text-gray-400"
                    />
                  </div>
                </div>
              </div>
              <div class="relative block">
                <span
                  class="absolute inset-y-0 left-0 flex items-center h-full pl-2"
                >
                  <font-awesome-icon icon="search" class="text-gray-400" />
                </span>
                <input
                  placeholder="Search"
                  class="h-[38px] appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                  v-model="filter.search"
                  @keydown="handleSearchKeyDown"
                />
              </div>
            </template>
            <template #default="{ data }">
              <td class="px-5 py-2">
                <div class="flex text-lg whitespace-no-wrap text-black-primary">
                  <StyledIconedLink
                    class="flex cursor-pointer mx-1.5"
                    @click.prevent="showTrainingStatus(data.driverId)"
                    :iconColor="data.trainingStatus"
                    :iconHoverColor="theme.secondaryColor"
                  >
                    <font-awesome-icon icon="circle" class="my-auto mx-1.5" />
                  </StyledIconedLink>
                </div>
              </td>
              <td class="relative px-5 py-2 text-center">
                <Dropdown ref="dropdown">
                  <ul class="py-2 text-sm">
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-100"
                        @click.prevent="viewDriver(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="eye" class="my-auto mr-2" />
                        View
                      </StyledIconedLink>
                    </li>
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-100"
                        @click.prevent="editDriver(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="edit" class="my-auto mr-2" />
                        Edit
                      </StyledIconedLink>
                    </li>
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-100"
                        @click.prevent="delinkDriver(data.driverId, businessId)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="unlink" class="my-auto mr-2" />
                        Delink
                      </StyledIconedLink>
                    </li>
                  </ul>
                </Dropdown>
              </td>
            </template>
            <template v-if="writeDrivers" #callToActionTop>
              <button
                  class="mr-1 btn btn-primary"
                  @click.prevent="inviteContractorByForm()"
                  :style="{ backgroundColor: theme.secondaryColor }"
              >
                Invite self-paid drivers
              </button>
              <button
                class="mr-1 btn btn-primary"
                @click.prevent="inviteContractorByCSV()"
                :style="{ backgroundColor: theme.secondaryColor }"
              >
                Invite self-paid driver by CSV
              </button>
              <button
                class="btn btn-primary"
                @click.prevent="newContractor()"
                :style="{ backgroundColor: theme.secondaryColor }"
              >
                Link to self-paid driver
              </button>
            </template>
            <template v-if="writeDrivers" #informationalPanel>
                <div class="flex items-center px-3 py-2 mt-1 text-sm bg-white shadow">
                  <font-awesome-icon
                    icon="info-circle"
                    size="lg"
                    class="mr-2 text-blue"
                  />
                  <span>This section displays and links with drivers who covered their own subscription.</span>
                </div>
                <div
                  v-if="isDriverContractTarget"
                  class="p-3 mt-2 bg-white shadow"
                  role="alert"
                >
                  <div :style="{ color: theme.primaryColor }" class="text-sm font-bold text-italic">
                    Available Seats: <span :style="{ color: theme.secondaryColor }">{{ totalAvailableSeat.contractor }}</span> remaining of {{ maximumSeatCountContractor }} subscribed.
                  </div>
                  <div class="mt-1 text-xs">
                    <router-link
                      to="/business/billing"
                      class="hover:bg-logmaster-400-light"
                    >
                      <span
                        class="underline"
                        :style="{ color: theme.secondaryColor }"
                        >See billing section</span
                      >
                    </router-link> to adjust seat total
                  </div>
                </div>
            </template>
          </Table>
        </Tab>
        <Tab title="Pending Self-paid" class="flex flex-col flex-1">
          <Table
            :isLoading="isLoading"
            :tableHeader="tableContractorHeader"
            :paginationSettings="paginationSettings"
            :data="pendingContractorsData"
            @onPageChange="onPageChange($event)"
            :has-multi-filter="true"
            @onMultiFilter="onMultiFilter"
          >
            <template #tableFilters>
              <div class="flex flex-row mb-1 sm:mb-0">
                <div class="relative">
                  <select
                    v-model="filter.limit"
                    @change="filterChange"
                    class="block w-full h-full px-4 py-2 pr-8 leading-tight text-gray-700 bg-white border border-gray-400 rounded-l appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                  >
                    <option :value="10">10</option>
                    <option :value="20">20</option>
                    <option :value="50">50</option>
                  </select>
                  <div
                    class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none"
                  >
                    <font-awesome-icon
                      icon="caret-down"
                      class="text-gray-400"
                    />
                  </div>
                </div>
              </div>
              <div class="relative block">
                <span
                  class="absolute inset-y-0 left-0 flex items-center h-full pl-2"
                >
                  <font-awesome-icon icon="search" class="text-gray-400" />
                </span>
                <input
                  placeholder="Search"
                  class="h-[38px] appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                  v-model="filter.search"
                  @keydown="handleSearchKeyDown"
                />
              </div>
            </template>
            <template #default="{ data }">
              <td class="relative px-5 py-2 text-center">
                <Dropdown ref="dropdown">
                  <ul class="py-2 text-sm">
                    <li
                      v-if="
                        data.status === 'pending_invite' ||
                        data.status === 'business_contract_creation'
                      "
                    >
                      <StyledIconedLink
                        class="block px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-100"
                        @click.prevent="resendContractorInvite(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon
                          icon="paper-plane"
                          class="my-auto mr-2"
                        />
                        Resend Invite
                      </StyledIconedLink>
                    </li>
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-100"
                        @click.prevent="rescindInviteContractor(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="times" class="my-auto mr-2" />
                        Rescind Invite
                      </StyledIconedLink>
                    </li>
                  </ul>
                </Dropdown>
              </td>
            </template>
            <template v-if="writeDrivers" #informationalPanel>
                <div
                  v-if="isDriverContractTarget"
                  class="p-3 mt-3 bg-white shadow"
                  role="alert"
                >
                  <div :style="{ color: theme.primaryColor }" class="text-sm font-bold text-italic">
                    Available Seats: <span :style="{ color: theme.secondaryColor }">{{ totalAvailableSeat.contractor }}</span> remaining of {{ maximumSeatCountContractor }} subscribed.
                  </div>
                  <div class="mt-1 text-xs">
                    <router-link
                      to="/business/billing"
                      class="hover:bg-logmaster-400-light"
                    >
                      <span
                        class="underline"
                        :style="{ color: theme.secondaryColor }"
                        >See billing section</span
                      >
                    </router-link> to adjust seat total
                  </div>
                </div>
            </template>
          </Table>
        </Tab>
        <Tab title="Non-EWD" class="flex flex-col flex-1">
          <Table
            :isLoading="isLoading"
            :tableHeader="nonEwdTableHeader"
            :paginationSettings="paginationSettings"
            :data="driversData"
            @onPageChange="onPageChange($event)"
            :has-multi-filter="true"
            @onMultiFilter="onMultiFilter"
          >
            <template #tableFilters>
              <div class="flex flex-row mb-1 sm:mb-0">
                <div class="relative">
                  <select
                    v-model="filter.limit"
                    @change="filterChange"
                    class="block w-full h-full px-4 py-2 pr-8 leading-tight text-gray-700 bg-white border border-gray-400 rounded-l appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                  >
                    <option :value="10">10</option>
                    <option :value="20">20</option>
                    <option :value="50">50</option>
                  </select>
                  <div
                    class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none"
                  >
                    <font-awesome-icon
                      icon="caret-down"
                      class="text-gray-400"
                    />
                  </div>
                </div>
              </div>
              <div class="relative block">
                <span
                  class="absolute inset-y-0 left-0 flex items-center h-full pl-2"
                >
                  <font-awesome-icon icon="search" class="text-gray-400" />
                </span>
                <input
                  placeholder="Search"
                  class="h-[38px] appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                  v-model="filter.search"
                  @keydown="handleSearchKeyDown"
                />
              </div>
            </template>
            <template #default="{ data }">
              <td class="relative px-5 py-2 text-center">
                <Dropdown ref="dropdown">
                  <ul class="py-2 text-sm">
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-100"
                        @click.prevent="viewNonEWDDriver(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="eye" class="my-auto mr-2" />
                        View
                      </StyledIconedLink>
                    </li>
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-100"
                        @click.prevent="editNonEWDDriver(data.driverId)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="edit" class="my-auto mr-2" />
                        Edit
                      </StyledIconedLink>
                    </li>
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-100"
                        @click.prevent="
                          updateNonEWDContractStatus(
                            data.id,
                            'delinked-non-ewd'
                          )
                        "
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="unlink" class="my-auto mr-2" />
                        Delink
                      </StyledIconedLink>
                    </li>
                  </ul>
                  <div class="py-2">
                    <StyledIconedLink
                      class="block px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-100"
                      @click.prevent="setToEWDDriver(data.driverId)"
                      :iconHoverColor="theme.secondaryColor"
                    >
                      <font-awesome-icon icon="user" class="my-auto mr-2" />
                      Convert to EWD
                    </StyledIconedLink>
                  </div>
                </Dropdown>
              </td>
            </template>
            <template v-if="writeDrivers" #callToActionTop>
              <div class="flex flex-row mb-1 space-x-1 sm:mb-0">
                <button
                  class="btn btn-primary"
                  @click.prevent="newNonEwdDriverImport()"
                  :style="{ backgroundColor: theme.secondaryColor }"
                >
                  Import Non-EWD Driver
                </button>
                <button
                  class="btn btn-primary"
                  @click.prevent="addNonEWDDriver()"
                  :style="{ backgroundColor: theme.secondaryColor }"
                >
                  Add Non-EWD Driver
                </button>
              </div>
            </template>
            <template v-if="writeDrivers" #informationalPanel>
                <div
                  v-if="isDriverContractTarget"
                  class="p-3 mt-3 bg-white shadow"
                  role="alert"
                >
                  <div :style="{ color: theme.primaryColor }" class="text-sm font-bold text-italic">
                    Available Seats: <span :style="{ color: theme.secondaryColor }">{{ totalAvailableSeat.contractor }}</span> remaining of {{ maximumSeatCountContractor }} subscribed.
                  </div>
                  <div class="mt-1 text-xs">
                    <router-link
                      to="/business/billing"
                      class="hover:bg-logmaster-400-light"
                    >
                      <span
                        class="underline"
                        :style="{ color: theme.secondaryColor }"
                        >See billing section</span
                      >
                    </router-link> to adjust seat total
                  </div>
                </div>
            </template>
          </Table>
        </Tab>
        <Tab title="Delinked Non-EWD" class="flex flex-col flex-1">
          <Table
            :isLoading="isLoading"
            :tableHeader="tableHeader"
            :paginationSettings="paginationSettings"
            :data="driversData"
            :has-multi-filter="true"
            @onPageChange="onPageChange($event)"
          >
            <template #tableFilters>
              <div class="flex flex-row mb-1 sm:mb-0">
                <div class="relative">
                  <select
                    v-model="filter.limit"
                    @change="filterChange"
                    class="block w-full h-full px-4 py-2 pr-8 leading-tight text-gray-700 bg-white border border-gray-400 rounded-l appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                  >
                    <option :value="10">10</option>
                    <option :value="20">20</option>
                    <option :value="50">50</option>
                  </select>
                  <div
                    class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none"
                  >
                    <font-awesome-icon
                      icon="caret-down"
                      class="text-gray-400"
                    />
                  </div>
                </div>
              </div>
              <div class="relative block">
                <span
                  class="absolute inset-y-0 left-0 flex items-center h-full pl-2"
                >
                  <font-awesome-icon icon="search" class="text-gray-400" />
                </span>
                <input
                  placeholder="Search"
                  class="h-[38px] appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                  v-model="filter.search"
                  @keydown="handleSearchKeyDown"
                />
              </div>
            </template>
            <template #default="{ data }">
              <td class="relative px-5 py-2 text-center">
                <Dropdown ref="dropdown">
                  <ul class="py-2 text-sm">
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-100"
                        @click.prevent="viewNonEWDDriver(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="eye" class="my-auto mr-2" />
                        View
                      </StyledIconedLink>
                    </li>
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-100"
                        @click.prevent="editNonEWDDriver(data.driverId)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="edit" class="my-auto mr-2" />
                        Edit
                      </StyledIconedLink>
                    </li>
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-100"
                        @click.prevent="
                          updateNonEWDContractStatus(data.id, 'linked-non-ewd')
                        "
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="link" class="my-auto mr-2" />
                        Link
                      </StyledIconedLink>
                    </li>
                  </ul>
                </Dropdown>
              </td>
            </template>
          </Table>
        </Tab>
        <Tab
          :visible="isRootBusiness && depots.length > 0"
          title="All Sites"
          class="flex flex-col flex-1"
        >
          <Table
            :isLoading="isLoading"
            :tableHeader="tableHeader"
            :paginationSettings="paginationSettings"
            :data="driversData"
            @onPageChange="onPageChange($event)"
            :has-multi-filter="true"
            @onMultiFilter="onMultiFilter"
          >
            <template #tableFilters>
              <div class="flex flex-row mb-1 sm:mb-0">
                <div class="relative">
                  <select
                    v-model="filter.limit"
                    @change="filterChange"
                    class="block w-full h-full px-4 py-2 pr-8 leading-tight text-gray-700 bg-white border border-gray-400 rounded-l appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                  >
                    <option :value="10">10</option>
                    <option :value="20">20</option>
                    <option :value="50">50</option>
                  </select>
                  <div
                    class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none"
                  >
                    <font-awesome-icon
                      icon="caret-down"
                      class="text-gray-400"
                    />
                  </div>
                </div>
              </div>
              <div class="relative block">
                <span
                  class="absolute inset-y-0 left-0 flex items-center h-full pl-2"
                >
                  <font-awesome-icon icon="search" class="text-gray-400" />
                </span>
                <input
                  placeholder="Search"
                  class="h-[38px] appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                  v-model="filter.search"
                  @keydown="handleSearchKeyDown"
                />
              </div>
            </template>
            <template #default="{ data }">
              <td class="relative px-5 py-2 text-center">
                <Dropdown ref="dropdown">
                  <ul class="py-2 text-sm">
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-100"
                        @click.prevent="viewDriver(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="eye" class="my-auto mr-2" />
                        View
                      </StyledIconedLink>
                    </li>
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-100"
                        @click.prevent="editDriver(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="edit" class="my-auto mr-2" />
                        Edit
                      </StyledIconedLink>
                    </li>
                  </ul>
                </Dropdown>
              </td>
            </template>
          </Table>
        </Tab>
        <Tab title="Connected Fleets" class="flex flex-col flex-1">
          <Table
            :isLoading="isLoading"
            :tableHeader="connectedFleetTableHeader"
            :paginationSettings="paginationSettings"
            :data="driversData"
            @onPageChange="onPageChange($event)"
            :has-multi-filter="true"
            @onMultiFilter="onMultiFilter"
            :defaultMessage="selectedFleetId ? null : 'Please Select a Fleet'"
          >
            <template #tableFilters>
              <div class="flex flex-row mb-1 sm:mb-0">
                <div class="relative">
                  <select
                    v-model="filter.limit"
                    @change="filterChange"
                    class="block w-full h-full px-4 py-2 pr-8 leading-tight text-gray-700 bg-white border border-gray-400 rounded-l appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                  >
                    <option :value="10">10</option>
                    <option :value="20">20</option>
                    <option :value="50">50</option>
                  </select>
                  <div
                    class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none"
                  >
                    <font-awesome-icon
                      icon="caret-down"
                      class="text-gray-400"
                    />
                  </div>
                </div>
              </div>
              <div class="relative block">
                <span
                  class="absolute inset-y-0 left-0 flex items-center h-full pl-2"
                >
                  <font-awesome-icon icon="search" class="text-gray-400" />
                </span>
                <input
                  placeholder="Search"
                  class="h-[38px] appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                  v-model="filter.search"
                  @keydown="handleSearchKeyDown"
                />
              </div>
            </template>
            <template #callToActionTop>
              <FormulateInput
                style="min-width: 200px"
                placeholder="Select Fleet"
                type="fleet-connection-select"
                label="Fleet Connection"
                :getOptionKey="(option) => option._id"
                :getOptionLabel="(option) => option.name"
                @input="onSelectFleetConnection($event)"
                :geotab="isGeotabAccount"
              />
            </template>
            <template #default="{ data }">
              <td class="px-5 py-2">
                <div class="flex text-lg whitespace-no-wrap text-black-primary">
                  <a
                    class="flex"
                    :class="
                      !data.isDriverSynced
                        ? 'cursor-pointer'
                        : 'cursor-not-allowed'
                    "
                    @click.prevent="
                      newFleetDriver(data.driverId, data.isDriverSynced)
                    "
                    ><font-awesome-icon
                      icon="sign-in-alt"
                      class="my-auto mx-1.5 hover:text-orange-primary-primary"
                  /></a>
                </div>
              </td>
            </template>
          </Table>
        </Tab>
      </Tabs>
    </div>
    <div
      v-if="!readDrivers && hasRole"
      class="w-full min-h-full mt-5 text-center"
    >
      <h1>
        Not authorized to view drivers. Please contact your business admin.
      </h1>
    </div>

    <div v-if="!hasRole" class="text-center">
      <h1>
        You are currently not associated with a role. Please contact support.
      </h1>
    </div>

    <Modal
      ref="modal"
      :title="modal.modalTitle"
      size="3xl"
      :height="modal.modalHeight"
      @closeModal="closeModal()"
    >
      <div v-if="modal.modalContent === 'new'">
        <div class="mb-16">
          <AddOrLinkDriver
            :detailsId="modal.modalId"
            @closeRefresh="closeRefresh"
          />
        </div>
      </div>

      <div v-if="modal.modalContent === 'view'">
        <DriverDetailsView
          @closeRefresh="closeRefresh"
          @editDriver="editDriver"
          :showDelink="true"
          :detailsId="modal.modalId"
        />
      </div>

      <div v-if="modal.modalContent === 'view-non-ewd'">
        <NonEWDDriverDetailsView
          :detailsId="modal.modalId"
          @editNonEWDDriver="editNonEWDDriver"
        />
      </div>

      <div v-if="modal.modalContent === 'edit-non-ewd'">
        <EditNonEWDDriver
          :detailsId="modal.modalId"
          @closeRefresh="closeRefresh"
        />
      </div>

      <div v-if="modal.modalContent === 'set-to-ewd'">
        <SetToWDDriver
          :detailsId="modal.modalId"
          @closeRefresh="closeRefresh"
        />
      </div>

      <div v-if="modal.modalContent === 'edit'">
        <EditBusinessProfile
          :detailsId="modal.modalId"
          @closeModal="closeModal"
          @closeRefresh="closeRefresh"
        />
      </div>

      <div v-if="modal.modalContent === 'edit-email'">
        <EditDriverEmail
          :detailsId="modal.modalId"
          @closeModal="closeModal"
          @refetch="fetchTab"
        />
      </div>

      <div v-if="modal.modalContent === 'new-business-driver'">
        <NewBusinessDriverProfile @closeRefresh="closeRefresh" />
      </div>

      <div v-if="modal.modalContent === 'new-driver-import'">
        <NewDriverImport
          @closeRefresh="closeRefresh"
          :isNonEwdImport="isNonEwdImport"
        />
      </div>

      <div v-if="modal.modalContent === 'new-business-profile'">
        <AddUser @closeRefresh="closeRefresh" :driverId="modal.modalId" />
      </div>

      <div v-if="modal.modalContent === 'new-contractor'">
        <NewContractorDriver @closeRefresh="closeRefresh" />
      </div>

      <div v-if="modal.modalContent === 'invite-contractors'">
        <NewContractorInvite
          @closeRefresh="closeRefresh"
          :availableSeats="totalAvailableSeat.contractor"
        />
      </div>

      <div v-if="modal.modalContent === 'invite-one-contractor'">
        <NewContractorInviteForm
            @closeRefresh="closeRefresh"
            :availableSeats="totalAvailableSeat.contractor"
        />
      </div>

      <div v-if="modal.modalContent === 'new-non-ewd-driver'">
        <NewNonEWDDriver @closeRefresh="closeRefresh" />
      </div>

      <div v-if="modal.modalContent === 'new-fleet-driver'">
        <div class="mb-16">
          <AddFleetDriver
            @closeRefresh="closeRefresh"
            @toast="toast"
            :stepperData="modal.modalData"
          />
        </div>
      </div>

      <div v-if="modal.modalContent === 'training-status'">
        <TrainingStatus
          :driverMongoId="modal.modalId"
          @closeRefresh="closeRefresh"
        />
      </div>

      <div v-if="modal.modalContent === 'add-driver-seat'">
        <div class="mb-16">
          <FormulateForm @submit="onSubmitBillingPage">
            <div class="mb-5">
              <label class="text-lg font-medium"
                >There is no available seat left. You have to add seats before
                add new drivers.</label
              >
            </div>
            <StyledSubmit :backgroundColor="theme.secondaryColor">
              <FormulateInput
                type="submit"
                label="Visit Billing Page"
                input-class="w-full btn btn-primary"
              />
            </StyledSubmit>
          </FormulateForm>
        </div>
      </div>

      <div v-if="modal.modalContent === 'contractor-invite-link'">
        <InviteLinkForm />
      </div>


    </Modal>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import { StyledIconedLink, StyledSubmit } from "@/styled-components";
import { Dropdown, Modal, Table } from "@/components/commons";
import Tabs from "@/components/commons/tab/Tabs";
import Tab from "@/components/commons/tab/Tab";
import {
  AddFleetDriver,
  AddOrLinkDriver,
  EditBusinessProfile,
  EditDriverEmail,
  EditNonEWDDriver,
  NewBusinessDriverProfile,
  NewContractorDriver,
  NewNonEWDDriver,
  SetToWDDriver,
} from "@/components/forms/business/driver/";

import { NewDriverImport } from "@/components/forms/business/driver-import";
import { NewContractorInvite, NewContractorInviteForm } from "@/components/forms/business/contractor-invite";
import { convertJSONToCSV, downloadCSV, formatDate, formatPaginationSettings, } from "@/_helper";
import { SEARCH_DEBOUNCE_DELAY } from "@/_helper/constants";
import DriverDetailsView from "@/components/view/driver/view-driver-with-contract";
import NonEWDDriverDetailsView from "@/components/view/driver/view-non-ewd-driver";
import TrainingStatus from "@/components/forms/business/driver/training-status.vue";
import { AddUser } from "@/components/forms/user/";
import InviteLinkForm from "@/components/forms/dynamic-links/invite-link-form.vue";

export default {
  name: "Business-Drivers",
  components: {
    StyledSubmit,
    TrainingStatus,
    Modal,
    AddOrLinkDriver,
    NewContractorDriver,
    NewContractorInvite,
    NewContractorInviteForm,
    NewNonEWDDriver,
    EditBusinessProfile,
    DriverDetailsView,
    NewBusinessDriverProfile,
    Dropdown,
    Table,
    Tab,
    Tabs,
    NewDriverImport,
    NonEWDDriverDetailsView,
    EditNonEWDDriver,
    SetToWDDriver,
    AddFleetDriver,
    StyledIconedLink,
    EditDriverEmail,
    AddUser,
    InviteLinkForm,
  },
  data() {
    return {
      businessId: "",
      isLoading: false,
      drivers: [],
      contractors: [],
      tableHeader: [
        {
          name: "Full Name",
          type: "text", //text, date, number
          fieldName: "driver.driverName",
          isFilterable: true,
        },
        {
          name: "Email Address",
          type: "text", //text, date, number
          fieldName: "driver.emailAddress",
          isFilterable: true,
        },
        {
          name: "Licence",
          type: "text", //text, date, number
          fieldName: "driver.driversLicenseNumber",
          isFilterable: true,
        },
        {
          name: "Phone",
          type: "number", //text, date, number
          fieldName: "driver.supportPhone",
          isFilterable: true,
        },
        {
          name: "BFM",
          type: "text", //text, date, number
          fieldName: "driver.BFMNumber",
          isFilterable: true,
        },
        {
          name: "AFM",
          type: "text",
          fieldName: "driver.AFMNumber",
          isFilterable: true,
        },
        {
          name: "App",
          type: "text",
          fieldName: "driver.app_version",
          isFilterable: false,
        },
        {
          name: "Date Created",
          type: "date", //text, date, number
          fieldName: "createdAt",
          isFilterable: true,
        },
      ],
      connectedFleetTableHeader: [
        {
          name: "Full Name",
          type: "text", //text, date, number
          fieldName: "driver.driverName",
          isFilterable: true,
        },
        {
          name: "Email Address",
          type: "text", //text, date, number
          fieldName: "driver.emailAddress",
          isFilterable: true,
        },
        {
          name: "Licence",
          type: "text", //text, date, number
          fieldName: "driver.driversLicenseNumber",
          isFilterable: true,
        },
        {
          name: "Synced with Logmaster",
          type: "boolean",
          fieldName: "isDriverSynced",
          isFilterable: true,
        },
      ],
      tableHeaderWithTraining: [
        {
          name: "Full Name",
          type: "text", //text, date, number
          fieldName: "driver.driverName",
          isFilterable: true,
        },
        {
          name: "Email Address",
          type: "text", //text, date, number
          fieldName: "driver.emailAddress",
          isFilterable: true,
        },
        {
          name: "Licence",
          type: "text", //text, date, number
          fieldName: "driver.driversLicenseNumber",
          isFilterable: true,
        },
        {
          name: "Phone",
          type: "number", //text, date, number
          fieldName: "driver.supportPhone",
          isFilterable: true,
        },
        {
          name: "BFM",
          type: "text", //text, date, number
          fieldName: "driver.BFMNumber",
          isFilterable: true,
        },
        {
          name: "AFM",
          type: "text",
          fieldName: "driver.AFMNumber",
          isFilterable: true,
        },
        {
          name: "App",
          type: "text",
          fieldName: "driver.app_version",
          isFilterable: false,
        },
        {
          name: "Date Created",
          type: "date", //text, date, number
          fieldName: "createdAt",
          isFilterable: true,
        },
        {
          name: "Training",
          type: "text", //text, date, number
          fieldName: "trainingStatus",
          isFilterable: true,
        },
      ],
      tableContractorHeader: [
        {
          name: "Email Address",
          type: "text", //text, date, number
          fieldName: "emailAddress",
          isFilterable: true,
        },

        {
          name: "Status",
          type: "text", //text, date, number
          fieldName: "status",
          isFilterable: true,
        },
      ],
      nonEwdTableHeader: [
        {
          name: "Full Name",
          type: "text", //text, date, number
          fieldName: "driver.driverName",
          isFilterable: true,
        },
        {
          name: "Email Address",
          type: "text", //text, date, number
          fieldName: "driver.emailAddress",
          isFilterable: true,
        },
        {
          name: "Licence",
          type: "text", //text, date, number
          fieldName: "driver.driversLicenseNumber",
          isFilterable: true,
        },
        {
          name: "Phone",
          type: "number", //text, date, number
          fieldName: "driver.supportPhone",
          isFilterable: true,
        },
        {
          name: "BFM",
          type: "text", //text, date, number
          fieldName: "driver.BFMNumber",
          isFilterable: true,
        },
        {
          name: "AFM",
          type: "text",
          fieldName: "driver.AFMNumber",
          isFilterable: true,
        },
        {
          name: "Vehicle",
          type: "text",
          fieldName: "driver.vehiclePlate",
          isFilterable: false,
        },
        {
          name: "Date Created",
          type: "date", //text, date, number
          fieldName: "createdAt",
          isFilterable: true,
        },
      ],
      filter: {
        limit: 10,
        status: "all",
        search: "",
      },
      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 50,
        visiblePageItemCount: 3,
      },
      filterStatuses: {
        all: "All",
        linked: "Linked",
        pending: "Pending",
      },
      modal: {
        modalContent: false,
        modalTitle: "",
        modalId: "",
        modalHeight: "80vh",
        modalData: null,
      },
      readDrivers: false,
      writeDrivers: false,
      hasRole: true,
      me: null,
      isRootBusiness: false,
      isNonEwdImport: false,
      depots: [],
      tableHeight: 0,
      maximumSeatCount: {
        ewd: {
          isSubscribed: false,
          count: 0,
        },
        fatigue: {
          isSubscribed: false,
          count: 0,
        },
        km: {
          isSubscribed: false,
          count: 0,
        },
      },
      maximumSeatCountContractor: 0,
      currentSeatCounts: {},
      availableSeats: {},
      addImportAccess: {
        ewd: true,
        fatigue: true,
        kmtime: true,
      },
      totalAvailableSeat: {
        ewd: 0,
        contractor: 0,
      },
      multiFilter: {},
      isDriverContractTarget: false,
      selectedFleetId: null,
    };
  },
  async mounted() {
    this.isLoading = true;
    this.$emit("updateLoading", true);
    this.me = this.$store.getters[`account/me`];
    this.businessId = this.me?.businessId;

    if (this.me?.business) {
      const proxySite = this.$store.getters[`account/proxySite`];
      const isProxySite = !_.isEmpty(proxySite) && _.isObject(proxySite);
      this.isRootBusiness = isProxySite ? false : true;
    }

    this.hasDriverProfile();
    let securityRoles = this.me?.securityRoles;
    if (securityRoles && securityRoles.length > 0) {
      const securityAccessObj = await this.setSecurityAccess(securityRoles);
      this.readDrivers = securityAccessObj.readDrivers;
      this.writeDrivers = securityAccessObj.writeDrivers;
    } else {
      this.hasRole = false;
    }
    this.$emit("updateLoading", false);
    this.depots = await this.$store.dispatch(`business/getSites`, {
      depotId: this.me?.business?._id,
      isDeleted: false,
    });

    await this.init();
    if (this.me?.contract?.contractTarget === "driver") {
      this.isDriverContractTarget = true;
      //Calculate Total Seats
      this.me?.business?.modules.map((module) => {
        if (module.apiTag == "ewd-ft") {
          this.maximumSeatCount.ewd.count = module.minimums;
          this.maximumSeatCount.ewd.isSubscribed = true;
        } else if (module.apiTag == "fatigue-compliance") {
          this.maximumSeatCount.fatigue.count = module.minimums;
          this.maximumSeatCount.fatigue.isSubscribed = true;
        } else if (module.apiTag == "km-time") {
          this.maximumSeatCount.km.count = module.minimums;
          this.maximumSeatCount.km.isSubscribed = true;
        }
      });

      //Check for module availability

      await this.getCurrentSeatCountForBusiness();
    }

    this.$emit("updateLoading", false);

    if (this.$store.state.driver.showDriverProfileModal) {
      this.newBusinessDriverProfile();
    }
  },
  watch: {
    "$store.state.account.roleNames": async function () {
      this.hasDriverProfile();
    },
    "$store.state.driver.showDriverProfileModal": async function () {
      if (this.$store.state.driver.showDriverProfileModal)
        this.newBusinessDriverProfile();
    },
    isGeotabAccount(value) {
      if (value) {
        this.fetchTab("Connected Fleets");
      }
    },
    selectedFleetId(value) {
      if (value) {
        this.init();
      } else if (this.filter.status === "connected-fleets") {
        this.drivers = [];
      }
    },
  },
  methods: {
    async fetchTab(tab) {
      this.multiFilter = {};

      switch (tab) {
        case "Connected Fleets":
          this.filter.status = "connected-fleets";
          break;
        case "Linked":
          this.filter.status = "linked";
          break;
        case "Pending":
          this.filter.status = "pending";
          break;
        case "Pending Self-paid":
          this.filter.status = "pending-contractor";
          break;
        case "Delinked":
          this.filter.status = "delinked";
          break;
        case "Self-paid":
          this.filter.status = "contractor";
          break;
        case "Non-EWD":
          this.filter.status = "linked-non-ewd";
          break;
        case "Delinked Non-EWD":
          this.filter.status = "delinked-non-ewd";
          break;
        case "All Sites":
          this.filter.status = "all-sites";
          break;
      }
      this.init();
    },
    setSecurityAccess(securityRoles) {
      let initialReadDrivers = false;
      let initialWriteDrivers = false;
      securityRoles.forEach((securityRole) => {
        const securityConfig = securityRole.securityConfig;
        if (securityConfig.drivers.readDrivers?.value) {
          initialReadDrivers = securityConfig.drivers.readDrivers.value;
        }
        if (securityConfig.drivers.writeDrivers?.value) {
          initialWriteDrivers = securityConfig.drivers.writeDrivers.value;
        }
      });
      return {
        readDrivers: initialReadDrivers,
        writeDrivers: initialWriteDrivers,
      };
    },
    async init(paged = 1) {
      this.isLoading = true;
      if (this.filter.status === "connected-fleets") {
        this.drivers = [];
        if (this.selectedFleetId) {
          this.drivers = await this.fetchFleetDrivers();
        }
        this.isLoading = false;
        return;
      }

      if (this.filter.status === "pending-contractor") {
        await this.fetchPendingContractors(paged);
        this.isLoading = false;
        return;
      }

      const me = this.$store.getters[`account/me`];
      const query = {
        skip: paged * this.filter.limit - this.filter.limit,
        limit: this.filter.limit,
        search: this.filter.search,
        filter: {},
      };

      if (Object.keys(this.multiFilter).length > 0) {
        query.filter = { ...this.multiFilter };
      }

      if (this.filter.status != "all-sites")
        query.filter.status = { $eq: this.filter.status };
      else {
        const siteIds = this.depots.map((x) => x._id);
        query.filter.siteId = { $in: siteIds };
        query.filter.status = { $nin: ["delinked", "delinked-non-ewd"] };
      }

      if (this.filter.status === "linked") {
        query.filter = { ...query.filter, ...{ $or: [{isContractor: false}, {isContractor: { $exists: false }}] } }
      }
      if (this.filter.status === "contractor") {
        delete query.filter.status;
        query.filter.isContractor = { $exists: true, $eq: true };
        query.filter.status = { $in: ["linked"] };
      }

      let driverContract = await this.$store.dispatch(
        `business/getContractByBusiness`,
        { uid: me?.businessId, query }
      );

      if (driverContract) {
        if (driverContract?.metadata) {
          this.paginationSettings = formatPaginationSettings(
            driverContract?.metadata
          );
          driverContract = driverContract.results;
        }

        this.drivers = this.formatItem(driverContract);
      }

      if (this.$refs["dropdown"]) this.$refs.dropdown.updateTableHeight();
      this.isLoading = false;
    },
    async onPageChange(event) {
      if (event.page) {
        await this.init(event.page);
      }
    },
    async filterChange() {
      await this.init();
    },
    handleSearchKeyDown() {
      this.stoppedTyping();
    },
    debouncedSearchString() {
      this.init();
    },
    newDriver() {
      if (this.totalAvailableSeat.ewd < 1 && this.isDriverContractTarget) {
        this.$refs.modal.openModal();
        this.modal.modalContent = "add-driver-seat";
        this.modal.modalTitle = "Add Driver Seats";
      } else {
        this.$refs.modal.openModal();
        this.modal.modalContent = "new";
        this.modal.modalTitle = "Add / Link Driver";
        this.modal.modalId = "";
      }
    },
    showInviteLink() {
      this.$refs.modal.openModal();
      this.modal.modalContent = "contractor-invite-link";
      this.modal.modalTitle = "Self-paid Invite Link";
    },
    newFleetDriver(id, isDriverSynced) {
      if (isDriverSynced) {
        return;
      }
      const targetDriver = this.drivers.find(
        (driver) => driver.emailAddress === id
      );

      const externalDriverId = targetDriver.externalDriverId;
      const defaultDataPayload = {
        ...targetDriver,
        fleetMetadata: {
          fleetConnectionId: this.selectedFleetId,
          externalDriverId,
        },
      };
      this.modal.modalData = defaultDataPayload;
      this.$refs.modal.openModal();
      this.modal.modalContent = "new-fleet-driver";
      this.modal.modalTitle = "Link Fleet Driver";
    },
    newEwdDriverImport() {
      if (this.totalAvailableSeat.ewd < 1 && this.isDriverContractTarget) {
        this.$refs.modal.openModal();
        this.modal.modalContent = "add-driver-seat";
        this.modal.modalTitle = "Add Driver Seats";
      } else {
        this.isNonEwdImport = false;
        this.$refs.modal.openModal();
        this.modal.modalContent = "new-driver-import";
        this.modal.modalTitle = "Import EWD Driver";
      }
    },
    newNonEwdDriverImport() {
      if (
        this.totalAvailableSeat.contractor < 1 &&
        this.isDriverContractTarget
      ) {
        this.$refs.modal.openModal();
        this.modal.modalContent = "add-driver-seat";
        this.modal.modalTitle = "Add Driver Seats";
      } else {
        this.isNonEwdImport = true;
        this.$refs.modal.openModal();
        this.modal.modalContent = "new-driver-import";
        this.modal.modalTitle = "Import Non-EWD Driver";
      }
    },
    onSubmitBillingPage() {
      this.$router.push(`/business/billing`);
    },
    async resetDriverPassword(email) {
      const { value: answer } = await this.$swal.fire({
        title: `Reset Driver Password`,
        text: `Continue sending reset password email?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, send it now!",
        cancelButtonText: "Cancel",
      });

      if (answer) {
        const response = await this.$store.dispatch(
          `account/requestResetPassword`,
          { email: email }
        );
        if (response.success) {
          this.$store.commit("setDialogNotify", true);
          this.$store.commit("setMessageNotify", {
            state: "success",
            message: "Reset password email sent.",
          });
        }
      }
    },
    delinkDriver(driverId, businessId) {
      if (confirm("Delink this driver?")) {
        this.$store
          .dispatch("business/delinkContract", {
            driverId: driverId,
            businessId: businessId,
          })
          .then((driver) => {
            this.$store.commit("setDialogNotify", true);
            this.$store.commit("setMessageNotify", {
              state: "success",
              message: "Driver has been delinked.",
            });
            if (driver) {
              this.init();
            }
          })
          .catch((error) => {
            this.errorMessage = `Something went wrong please try again.`;
            if (error.message) this.errorMessage = error.message[0];
            this.$store.commit("setDialogNotify", true);
            this.$store.commit("setMessageNotify", {
              state: "error",
              message: this.errorMessage,
            });
          });
      }
    },
    createNewProfile(driverId) {
      this.$refs.modal.openModal();
      this.modal.modalContent = "new-business-profile";
      this.modal.modalTitle = "Create Business Profile";
      this.modal.modalId = driverId;
    },
    updateNonEWDContractStatus(contractId, status) {
      const warningMsg =
        status == "linked-non-ewd"
          ? "Link non-EWD driver?"
          : "Delink non-EWD driver?";
      if (confirm(warningMsg)) {
        const payload = {
          contractId: contractId,
          status: status,
        };
        this.$store
          .dispatch("business/updateNonEWDContractStatus", payload)
          .then((driver) => {
            if (driver) {
              this.init();
            }
          })
          .catch((error) => {
            this.errorMessage = `Something went wrong please try again.`;
            if (error.message) this.errorMessage = error.message[0];
          });
      }
    },
    resendInvite(contractId) {
      if (confirm("Resend invite email to this driver?")) {
        this.$store
          .dispatch("business/resendInvite", contractId)
          .then((driver) => {
            this.$store.commit("setDialogNotify", true);
            this.$store.commit("setMessageNotify", {
              state: "success",
              message: "Driver invitation sent.",
            });
            if (driver) {
              this.init();
            }
          })
          .catch((error) => {
            this.errorMessage = `Something went wrong please try again.`;
            if (error.message) this.errorMessage = error.message[0];
          });
      }
    },
    rescindInvite(driverId) {
      if (
        confirm(
          "Are you sure to rescind this driver invite? You can't undo this."
        )
      ) {
        this.$store
          .dispatch("business/rescindInvite", driverId)
          .then((driver) => {
            this.$store.commit("setDialogNotify", true);
            this.$store.commit("setMessageNotify", {
              state: "success",
              message: "Driver invitation rescinded.",
            });
            if (driver) {
              this.init();
            }
          })
          .catch((error) => {
            this.errorMessage = `Something went wrong please try again.`;
            if (error.message) this.errorMessage = error.message[0];
          });
      }
    },
    rescindInviteContractor(contractId) {
      if (
        confirm(
          "Are you sure to rescind this contractor invite? You can't undo this."
        )
      ) {
        this.$store
          .dispatch("driver/rescindContractorInvite", contractId)
          .then((driver) => {
            this.$store.commit("setDialogNotify", true);
            this.$store.commit("setMessageNotify", {
              state: "success",
              message: "Driver invitation rescinded.",
            });
            if (driver) {
              this.init();
            }
          })
          .catch((error) => {
            this.errorMessage = `Something went wrong please try again.`;
            if (error.message) this.errorMessage = error.message[0];
          });
      }
    },
    resendContractorInvite(contractorId) {
      if (confirm("Resend invite email to this contractor?")) {
        this.$store
          .dispatch("driver/resendContractorInvite", contractorId)
          .then((driver) => {
            this.$store.commit("setDialogNotify", true);
            this.$store.commit("setMessageNotify", {
              state: "success",
              message: "Driver invitation sent.",
            });
            if (driver) {
              this.init();
            }
          })
          .catch((error) => {
            this.errorMessage = `Something went wrong please try again.`;
            if (error.message) this.errorMessage = error.message[0];
          });
      }
    },
    async getCurrentSeatCountForBusiness() {
      //check if there are depot configs
      this.depots = await this.$store.dispatch(`business/getSites`, {
        depotId: this.me?.business?._id,
        isDeleted: false,
      });

      let filter = {};
      if (this.depots.length > 0) {
        const siteIds = this.depots.map((x) => x._id);
        filter.siteId = { $in: siteIds };
      }

      await this.$store
        .dispatch(`driver/getCurrentSeatCountForBusiness`, {
          businessId: this.me.business?._id,
          filter: filter,
        })
        .then((response) => {
          this.currentSeatCounts = response.data;

          if (this.maximumSeatCount.ewd.isSubscribed == true) {
            this.availableSeats.ewd =
              this.maximumSeatCount.ewd.count - this.currentSeatCounts.ewdCount;
            if (this.availableSeats.ewd < 1) {
              this.addImportAccess.ewd = false;
            }
          }
          if (this.maximumSeatCount.fatigue.isSubscribed == true) {
            this.availableSeats.fatigue =
              this.maximumSeatCount.fatigue.count -
              this.currentSeatCounts.fatigueCount;
            if (this.availableSeats.fatigue < 1) {
              this.addImportAccess.fatigue = false;
            }
          }
          if (this.maximumSeatCount.km.isSubscribed == true) {
            this.availableSeats.kmtime =
              this.maximumSeatCount.km.count -
              this.currentSeatCounts.kmTimeCount;
            if (this.availableSeats.kmtime < 1) {
              this.addImportAccess.kmtime = false;
            }
          }

          //check which of the subscription is the lowest
          let ewdArray = [];
          let contractorArray = [];

          this.maximumSeatCountContractor = Object.entries(this.maximumSeatCount)
            .filter(([key]) => key !== 'ewd')
            .reduce((max, [, value]) => value.count > max ? value.count : max, 0);

          if (this.maximumSeatCount.ewd.isSubscribed == true) {
            ewdArray.push(this.availableSeats.ewd);
          }
          if (this.maximumSeatCount.fatigue.isSubscribed == true) {
            ewdArray.push(this.availableSeats.fatigue);
            contractorArray.push(this.availableSeats.fatigue);
          }
          if (this.maximumSeatCount.km.isSubscribed == true) {
            ewdArray.push(this.availableSeats.kmtime);
            contractorArray.push(this.availableSeats.kmtime);
          }
          const subscribedEWD = ewdArray.filter((seat) => seat !== undefined);
          const subscribedContractor = contractorArray.filter(
            (seat) => seat !== undefined
          );

          const availableEWDSeat = Math.min(...subscribedEWD);
          const availableContractorSeat = Math.min(...subscribedContractor);

          console.log("Remaining Seat--", availableEWDSeat);
          console.log("Remaining Contractor Seat--", availableContractorSeat);
          this.totalAvailableSeat.ewd = availableEWDSeat;
          this.totalAvailableSeat.contractor =
            availableContractorSeat == Infinity
              ? "No Limit"
              : availableContractorSeat;
        });
    },
    viewDriver(id) {
      this.$refs.modal.openModal();
      this.modal.modalContent = "view";
      this.modal.modalTitle = "Driver Info";
      this.modal.modalId = id;
    },
    viewNonEWDDriver(id) {
      this.$refs.modal.openModal();
      this.modal.modalContent = "view-non-ewd";
      this.modal.modalTitle = "Non-EWD Driver Info";
      this.modal.modalId = id;
    },
    editNonEWDDriver(id) {
      this.$refs.modal.openModal();
      this.modal.modalContent = "edit-non-ewd";
      this.modal.modalTitle = "Edit Non-EWD Driver";
      this.modal.modalId = id;
    },
    setToEWDDriver(id) {
      this.$refs.modal.openModal();
      this.modal.modalContent = "set-to-ewd";
      this.modal.modalTitle = "Set Non-EWD to EWD Driver";
      this.modal.modalId = id;
    },
    editDriver(id) {
      this.$refs.modal.openModal();
      this.modal.modalContent = "edit";
      this.modal.modalTitle = "Business Profile Edit";
      this.modal.modalId = id;
    },
    showTrainingStatus(id) {
      this.$refs.modal.openModal();
      this.modal.modalContent = "training-status";
      this.modal.modalTitle = "Driver Training Status";
      this.modal.modalId = id;
    },
    async editDriverEmail(id) {
      this.$refs.modal.openModal();
      this.modal.modalContent = "edit-email";
      this.modal.modalTitle = "Edit Driver Email";
      this.modal.modalId = id;
    },
    newBusinessDriverProfile() {
      this.$refs.modal.openModal();
      this.modal.modalContent = "new-business-driver";
      this.modal.modalTitle = "Add Business Driver Profile";
    },
    newContractor() {
      if (
        this.totalAvailableSeat.contractor < 1 &&
        this.isDriverContractTarget
      ) {
        this.$refs.modal.openModal();
        this.modal.modalContent = "add-driver-seat";
        this.modal.modalTitle = "Add Driver Seats";
      } else {
        this.$refs.modal.openModal();
        this.modal.modalContent = "new-contractor";
        this.modal.modalTitle = "Link with self-paid drivers";
      }
    },
    inviteContractorByCSV() {
      if (
        this.totalAvailableSeat.contractor < 1 &&
        this.isDriverContractTarget
      ) {
        this.$refs.modal.openModal();
        this.modal.modalContent = "add-driver-seat";
        this.modal.modalTitle = "Add Driver Seats";
      } else {
        this.$refs.modal.openModal();
        this.modal.modalContent = "invite-contractors";
        this.modal.modalTitle = "Invite to self-pay";
      }
    },
    inviteContractorByForm() {
      if (
          this.totalAvailableSeat.contractor < 1 &&
          this.isDriverContractTarget
      ) {
        this.$refs.modal.openModal();
        this.modal.modalContent = "add-driver-seat";
        this.modal.modalTitle = "Add Driver Seats";
      } else {
        this.$refs.modal.openModal();
        this.modal.modalContent = "invite-one-contractor";
        this.modal.modalTitle = "Invite to self-pay";
      }
    },
    addNonEWDDriver() {
      if (this.totalAvailableSeat.ewd < 1 && this.isDriverContractTarget) {
        this.$refs.modal.openModal();
        this.modal.modalContent = "add-driver-seat";
        this.modal.modalTitle = "Add Driver Seats";
      } else {
        this.$refs.modal.openModal();
        this.modal.modalContent = "new-non-ewd-driver";
        this.modal.modalTitle = "New Non-EWD Driver";
      }
    },
    relinkDriver(driverId) {
      if (this.totalAvailableSeat.ewd < 1 && this.isDriverContractTarget) {
        this.$refs.modal.openModal();
        this.modal.modalContent = "add-driver-seat";
        this.modal.modalTitle = "Add Driver Seats";
      } else {
        this.$refs.modal.openModal();
        this.modal.modalContent = "new";
        this.modal.modalTitle = "Relink Driver";
        this.modal.modalId = driverId;
      }
    },
    closeModal() {
      this.$refs.modal.closeModal();
      this.$store.commit("driver/setShowDriverProfileModal", false);
    },
    closeRefresh() {
      this.$refs.modal.closeModal();
      this.$store.commit("driver/setShowDriverProfileModal", false);
      this.init();
    },
    formatItem(items) {
      let nItems = [];
      if (items) {
        items.map((item) => {
          const webProfileRoles = item?.webProfiles?.map(
            (profile) => profile.parentRole
          );
          const driver = {
            id: item?._id,
            driverId: item?.driverId,
            name: item?.driver?.driverName,
            email: item?.driver?.emailAddress || "-",
            license: item?.driver?.driversLicenseNumber,
            phone: item?.driver?.supportPhone || "-",
            bfm: item?.profile?.BFMNumber || "-",
            afm: item?.profile?.AFMNumber || "-",
            status: item?.status ? item?.status.toUpperCase() : null,
            created: item?.createdAt ? formatDate(item?.createdAt) : "",
            siteId: this.filter.status === "all-sites" ? item?.siteId : null,
            trainingStatus:
              item?.driver?.isTrainingCompleted ||
              item?.trainingStatus === "finished"
                ? "#00FF00"
                : "#C00000",
            showAddProfileOption:
              (webProfileRoles && webProfileRoles.length < 2) || false,
            app_version: item?.driver?.app_version || "N/A",
            vehiclePlate: item?.driver?.vehicle?.vehiclePlate || "N/A",
          };
          nItems.push(driver);
        });
      }
      return nItems;
    },
    parseDrivers(data) {
      return data.map((driver) => {
        const mapped = [
          {
            driverId: driver.driverId,
            id: driver.id,
            name: driver.name,
            itemType: "name",
            hasImage: true,
            status: driver.status,
            trainingStatus: driver.trainingStatus,
            showAddProfileOption: driver?.showAddProfileOption,
            email: driver.email,
            vehiclePlate: driver.vehiclePlate,
          },
          {
            driverId: driver.driverId,
            id: driver.id,
            name: driver.email,
            itemType: "string",
          },
          {
            driverId: driver.driverId,
            id: driver.id,
            name: driver.license,
            itemType: "string",
          },
          {
            driverId: driver.driverId,
            id: driver.id,
            name: driver.phone,
            itemType: "string",
          },
          {
            driverId: driver.driverId,
            id: driver.id,
            name: driver.bfm,
            itemType: "string",
          },
          {
            driverId: driver.driverId,
            id: driver.id,
            name: driver.afm,
            itemType: "string",
          },
          {
            driverId: driver.driverId,
            id: driver.id,
            name: driver.app_version,
            itemType: "string",
          },
          {
            driverId: driver.driverId,
            id: driver.id,
            name: driver.created,
            itemType: "string",
          },
        ];
        let allSiteHeader = {
          name: "Site",
          type: "text",
          fieldName: "depot.name",
          isFilterable: false,
        };

        if (this.filter.status === "all-sites") {
          if (!this.tableHeader.some((header) => header.name === "Site")) {
            this.tableHeader.unshift(allSiteHeader);
          }
          const depot = _.find(this.depots, (x) => x._id === driver.siteId);
          mapped.unshift({
            driverId: driver.driverId,
            id: driver.id,
            name: depot?.name || "Main Business",
            itemType: "string",
          });
        } else {
          const index = this.tableHeader.findIndex(
            (allSiteHeader) => allSiteHeader.name === "Site"
          );
          if (index >= 0) this.tableHeader.splice(index, 1);
        }

        return mapped;
      });
    },
    parseFleetDrivers(data) {
      return data.map((driver) => [
        {
          driverId: driver.emailAddress,
          id: driver.externalSiteId,
          name: driver.driverName,
          itemType: "name",
          isDriverSynced: driver.isDriverSynced,
        },
        {
          driverId: driver.emailAddress,
          id: driver.externalSiteId,
          name: driver.emailAddress,
          itemType: "string",
          isDriverSynced: driver.isDriverSynced,
        },
        {
          driverId: driver.emailAddress,
          id: driver.externalSiteId,
          name: driver.driversLicenseNumber,
          itemType: "string",
          isDriverSynced: driver.isDriverSynced,
        },
        {
          driverId: driver.emailAddress,
          id: driver.externalSiteId,
          name: driver.isDriverSynced,
          itemType: "boolean",
          isDriverSynced: driver.isDriverSynced,
        },
      ]);
    },

    parsePendingContractors(data) {
      return data.map((contractor) => [
        {
          contractorId: contractor.emailAddress,
          id: contractor._id,
          name: contractor.emailAddress,
          itemType: "string",
          status: contractor.status,
        },
        {
          contractorId: contractor.emailAddress,
          id: contractor._id,
          name: contractor.status,
          itemType: "string",
        },
      ]);
    },

    parseNonEWDDrivers(data) {
      return data.map((driver) => {
        return [
          {
            driverId: driver.driverId,
            id: driver.id,
            name: driver.name,
            itemType: "name",
            hasImage: true,
            status: driver.status,
            trainingStatus: driver.trainingStatus,
            showAddProfileOption: driver?.showAddProfileOption,
            email: driver.email,
          },
          {
            driverId: driver.driverId,
            id: driver.id,
            name: driver.email,
            itemType: "string",
          },
          {
            driverId: driver.driverId,
            id: driver.id,
            name: driver.license,
            itemType: "string",
          },
          {
            driverId: driver.driverId,
            id: driver.id,
            name: driver.phone,
            itemType: "string",
          },
          {
            driverId: driver.driverId,
            id: driver.id,
            name: driver.bfm,
            itemType: "string",
          },
          {
            driverId: driver.driverId,
            id: driver.id,
            name: driver.afm,
            itemType: "string",
          },
          {
            driverId: driver.driverId,
            id: driver.id,
            name: driver.vehiclePlate,
            itemType: "string",
          },
          {
            driverId: driver.driverId,
            id: driver.id,
            name: driver.created,
            itemType: "string",
          },
        ];
      });
    },

    hasDriverProfile() {
      const roles = this.$store.getters["account/roleNames"];
      return roles.includes("driver");
    },

    async fetchFleetDrivers() {
      const response = await this.$store.dispatch("business/getFleetData", {
        businessId: this.me.businessId
          ? this.me.businessId
          : this.me?.business?._id,
        fleetConnectionId: this.selectedFleetId,
        dataType: "driver",
      });

      let drivers = [];
      if (response.success) {
        this.paginationSettings = {
          page: 1,
          totalPages: 1,
          totalRecords: response.data.length,
          visiblePageItemCount: 100,
        };
        drivers = response.data;
      } else {
        this.toast("error", response.message);
      }

      return drivers;
    },

    async fetchPendingContractors(paged) {
      const me = this.$store.getters[`account/me`];
      const query = {
        skip: paged * this.filter.limit - this.filter.limit,
        limit: this.filter.limit,
        search: this.filter.search,
        filter: {},
      };

      const pendingContractorsQuery = await this.$store.dispatch(
        "driver/getBusinessPendingContractors",
        { businessId: me.businessId, query }
      );
      if (pendingContractorsQuery) {
        this.paginationSettings = formatPaginationSettings(
          pendingContractorsQuery?.metadata
        );
        this.contractors = pendingContractorsQuery?.results;
      } else {
        this.paginationSettings = {
          page: 1,
          totalPages: 1,
          totalRecords: 1,
          visiblePageItemCount: 1,
        };
        this.contractors = [];
      }
    },

    toast(state, msg) {
      const message = {
        state: state,
        message: msg,
      };
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", message);
    },

    async downloadDrivers() {
      const business = this.me.business;
      const query = {
        skip: 0,
        limit: -1,
        filter: { $or: [{ status: "linked" }, { status: "linked-non-ewd" }] },
      };
      const driverContracts = await this.$store.dispatch(
        `business/getContractByBusiness`,
        { uid: this.me.businessId, query }
      );
      if (driverContracts.results && driverContracts.results.length > 1) {
        const filteredContracts = _.uniqBy(driverContracts.results, "driverId");

        let driverJSON = [];
        filteredContracts.forEach((contract) => {
          driverJSON.push({
            emailAddress: contract.driver.emailAddress ?? "",
            driverName: contract.driver.driverName ?? "",
            driverDateOfBirth:
              formatDate(contract.driver.driverDateOfBirth, "DD/MM/YYYY") ?? "",
            driversLicenseNumber: contract.driver.driversLicenseNumber ?? "",
            driversLicenseExpiry:
              formatDate(contract.driver.driversLicenseExpiry, "DD/MM/YYYY") ??
              "",
            driversLicenseState:
              contract.driver.driversLicenseState.split(" ")[0] ?? "",
            driversBaseLocation: contract.driver?.driversBaseLocation ?? "",
            recordKeeperAddress: contract?.profile?.recordKeeperAddress ?? "",
            driversBaseTimeZone: contract.driver?.driversBaseTimeZone ?? "",
            engineRegion: contract.driver?.engineRegion ?? "",
            bfmNumber: contract?.profile?.BFMNumber ?? "",
          });
        });

        let csvString =
          "Email,Name,DOB,Licence number,Licence expiry date,Licence issue state,Base Location,Record Keeper Address,Base time zone,Region,BFM number if applicable\r\n";
        csvString += convertJSONToCSV(JSON.parse(JSON.stringify(driverJSON)));
        downloadCSV(
          csvString,
          `${business.persona?.businessName
            .toLowerCase()
            .replace(/\s/g, "-")}-drivers`
        );
        await this.toast("success", "CSV successfully generated.");
      } else {
        await this.toast("error", "No drivers found.");
      }
    },
    async onMultiFilter(data) {
      this.multiFilter = { ...data };
      this.init();
    },

    async resendInvitesToAll() {
      const { value: answer } = await this.$swal.fire({
        title: `Resend Invites To All`,
        text: `Are you sure you want to resend invites to all pending drivers?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, send it now!",
        cancelButtonText: "Cancel",
      });
      if (answer) {
        this.$store
          .dispatch("business/resendInviteByBusiness", this.businessId)
          .then((driver) => {
            this.$store.commit("setDialogNotify", true);
            this.$store.commit("setMessageNotify", {
              state: "success",
              message: "Driver invitations resent.",
            });
            if (driver) {
              this.init();
            }
          })
          .catch((error) => {
            this.errorMessage = `Something went wrong please try again.`;
            if (error.message) this.errorMessage = error.message[0];
          });
      }
    },

    onSelectFleetConnection(data) {
      this.selectedFleetId = data?._id;
    },
  },
  computed: {
    driversData() {
      if (this.drivers) {
        if (this.filter.status === "connected-fleets") {
          return this.parseFleetDrivers(this.drivers);
        } else if (this.filter.status === "linked-non-ewd") {
          return this.parseNonEWDDrivers(this.drivers);
        } else {
          return this.parseDrivers(this.drivers);
        }
      } else {
        return [];
      }
    },
    pendingContractorsData() {
      if (this.contractors) {
        return this.parsePendingContractors(this.contractors);
      } else {
        return [];
      }
    },
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
    isGeotabAccount() {
      return this.$store.getters["account/getIsGeotabAccount"];
    },
  },
  created() {
    this.stoppedTyping = _.debounce(
      this.debouncedSearchString,
      SEARCH_DEBOUNCE_DELAY,
      {
        leading: false,
        trailing: true,
      }
    );
  },
};
</script>
