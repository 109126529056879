<template>
  <div>
    <div class="h-full flex flex-col space-y-4">
      <Table
        :isLoading="isLoading"
        :tableHeader="tableHeader"
        :paginationSettings="paginationSettings"
        :data="fleetConnections"
        @onPageChange="onPageChange($event)"
      >
        <template #tableFilters>
          <div class="flex flex-row mb-1 sm:mb-0">
            <div class="relative">
              <select
                v-model="filter.provider"
                @change="filterChange"
                class="appearance-none h-full border border-r-none -backdrop-hue-rotate-15block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"
              >
                <option
                  v-for="(filterProvider, key) in filterProviders"
                  :key="key"
                  :value="key"
                >
                  {{ filterProvider }}
                </option>
              </select>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
              >
                <font-awesome-icon icon="caret-down" class="text-gray-400" />
              </div>
            </div>
          </div>
          <div class="block relative">
            <span
              class="h-full absolute inset-y-0 left-0 flex items-center pl-2"
            >
              <font-awesome-icon icon="search" class="text-gray-400" />
            </span>
            <input
              placeholder="Name"
              class="h-[38px] appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
              v-model="filter.search"
              @keydown="handleSearchKeyDown"
            />
          </div>
        </template>
        <template #callToActionTop>
          <div class="flex">
            <button
              class="btn btn-primary mr-2"
              @click.prevent="addFleetConnection()"
              :style="{ backgroundColor: theme.secondaryColor }"
            >
              Add
            </button>
          </div>
        </template>
        <template #default="{ data }">
          <td class="px-5 py-2 text-center relative">
            <Dropdown ref="dropdown">
              <ul class="py-2 text-sm">
                <li>
                  <StyledIconedLink
                    class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                    @click.prevent="editFleetConnection(data.id)"
                    :iconHoverColor="theme.secondaryColor"
                  >
                    <font-awesome-icon icon="edit" class="my-auto mr-2" /> Edit
                  </StyledIconedLink>
                </li>
                <li>
                  <StyledIconedLink
                    class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                    @click.prevent="editFleetConnectionFunctions(data.id)"
                    :iconHoverColor="theme.secondaryColor"
                  >
                    <font-awesome-icon icon="wrench" class="my-auto mr-2" />
                    Functions
                  </StyledIconedLink>
                </li>
                <li>
                  <StyledIconedLink
                    class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                    @click.prevent="deleteFleetConnectionModal(data.id)"
                    :iconHoverColor="theme.secondaryColor"
                  >
                    <font-awesome-icon icon="times" class="my-auto mr-2" />
                    Delete
                  </StyledIconedLink>
                </li>
              </ul>
            </Dropdown>
          </td>
        </template>
      </Table>
    </div>
    <Modal
      ref="modal"
      :title="modalTitle"
      :size="modalSize"
      :description="modalDescription"
    >
      <div v-if="modalType === 'edit'">
        <EditFleetConnectionForm
          :fleetConnection="selectedFleetConnection"
          @toast="toast"
          @closeModal="closeModal"
        />
      </div>
      <div v-if="modalType === 'edit-func'">
        <EditFleetConnectionFunctionForm
          :fleetConnection="selectedFleetConnection"
          @toast="toast"
          @closeModal="closeModal"
        />
      </div>
      <div v-if="modalType === 'new'">
        <AddFleetConnectionForm @closeRefresh="closeModal" @toast="toast" />
      </div>
      <!--TODO: Move to a general componenet-->
      <div v-if="modalType === 'delete'">
        <div>
          <div>
            <div class="mb-5">
              <div class="flex flex-row">
                <div class="font-bold text-black-primary text-base">
                  Are you sure you want to delete this fleet connection?
                </div>
              </div>
              <div class="flex flex-row mt-8">
                <button
                  @click.prevent="closeModal(false)"
                  class="w-1/2 px-2 mr-2 btn btn-primary"
                  :style="{
                    background: theme.primaryColor,
                    color: 'white',
                  }"
                  :disabled="deleteFleetLoading"
                >
                  Cancel
                </button>
                <button
                  @click.prevent="deleteFleetConnection()"
                  class="w-1/2 px-2 ml-2 btn btn-primary"
                  :disabled="deleteFleetLoading"
                >
                  <span v-if="deleteFleetLoading"
                    ><font-awesome-icon
                      icon="spinner"
                      class="mr-1 loader"
                    />Deleting...</span
                  >
                  <span v-else>Delete</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import {
  AddFleetConnectionForm,
  EditFleetConnectionForm,
  EditFleetConnectionFunctionForm,
} from "@/components/forms/business/fleet";

import _ from "lodash";
import { mapGetters } from "vuex";
import { Table, Dropdown, Modal } from "@/components/commons";
import { formatPaginationSettings, formatDate } from "@/_helper";
import { SEARCH_DEBOUNCE_DELAY } from "@/_helper/constants";
import { StyledIconedLink } from "@/styled-components";
export default {
  name: "Connected-Fleet",
  components: {
    Table,
    Dropdown,
    Modal,
    StyledIconedLink,
    EditFleetConnectionForm,
    EditFleetConnectionFunctionForm,
    AddFleetConnectionForm,
  },
  data() {
    return {
      isLoading: false,
      tableHeader: ["Connection Name", "Provider", "Start Date"],
      filter: {
        limit: 20,
        search: "",
        provider: "all",
      },
      filterProviders: {
        all: "All",
        geotab: "Geotab",
        mix: "Mix",
        verizon: "Verizon",
        lms: "LMS",
        webfleet: "Webfleet",
        linxio: "Linxio",
      },
      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 50,
        visiblePageItemCount: 3,
        paginationDescription: "Displaying fleet connections",
      },
      me: null,
      rawFleetConnections: [],
      modalSize: "3xl",
      modalTitle: "",
      modalType: null,
      modalDescription: null,
      deleteFleetLoading: false,
      selectedFleetConnection: null,
    };
  },
  created() {
    this.stoppedTyping = _.debounce(
      this.debouncedSearchString,
      SEARCH_DEBOUNCE_DELAY,
      {
        leading: false,
        trailing: true,
      }
    );
  },
  mounted() {
    this.me = this.$store.getters[`account/me`];
    this.business = this.me?.business;
    this.init();
  },
  methods: {
    async init(page = 1) {
      try {
        this.isLoading = true;
        const query = {
          businessId: this.me.businessId
            ? this.me.businessId
            : this.me?.business?._id,
          pagination: {
            skip: page * this.filter.limit - this.filter.limit,
            limit: this.filter.limit,
            search: this.filter.search,
            filter: {},
          },
        };

        if (this.filter.provider !== "all") {
          query.pagination.filter = {
            provider: this.filter.provider,
          };
        }

        const result = await this.$store.dispatch(
          "business/getFleetConnections",
          query
        );

        this.paginationSettings = {
          ...this.paginationSettings,
          ...formatPaginationSettings(result.resultsMeta),
        };
        this.rawFleetConnections = result.data;
      } catch (error) {
        console.log("error: ", error);
        this.toast("error", "Something went wrong");
      } finally {
        this.isLoading = false;
      }
    },

    toast(state, msg) {
      const message = {
        state: state,
        message: msg,
      };
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", message);
    },

    onPageChange(event) {
      if (event.page) {
        this.init(event.page);
      }
    },

    formatDate(date) {
      return formatDate(date, "DD/MM/YYYY", true);
    },

    handleSearchKeyDown() {
      this.stoppedTyping();
    },
    debouncedSearchString() {
      this.init();
    },

    formatData(items = []) {
      return items.map((item) => {
        const { _id: id, name, provider, startDate } = item;
        return [
          {
            id,
            name,
            itemType: "string",
          },
          {
            id,
            name: provider,
            itemType: "string",
          },
          {
            id,
            name: this.formatDate(startDate),
            itemType: "string",
          },
        ];
      });
    },

    closeModal(refresh = true) {
      this.$refs.modal.closeModal();
      this.modalType = "";
      this.modalTitle = "";
      this.modalDescription = "";
      this.selectedFleetConnection = null;
      if (refresh) {
        this.init();
      }
    },

    addFleetConnection() {
      this.$refs.modal.openModal();
      this.modalType = "new";
      this.modalTitle = "Add Fleet Connection";
      this.modalDescription = "";
    },

    editFleetConnection(id) {
      this.$refs.modal.openModal();
      this.modalType = "edit";
      this.modalTitle = "Edit Fleet Connection";
      this.modalDescription = "";
      this.selectedFleetConnectionId = id;
      this.selectedFleetConnection = this.rawFleetConnections.find(
        (fleet) => fleet._id === id
      );
    },

    editFleetConnectionFunctions(id) {
      this.$refs.modal.openModal();
      this.modalType = "edit-func";
      this.modalTitle = "Edit Fleet Connection Functions";
      this.modalDescription =
        "Enable or disable functions for this fleet connection.";
      this.selectedFleetConnectionId = id;
      this.selectedFleetConnection = this.rawFleetConnections.find(
        (fleet) => fleet._id === id
      );
    },
    deleteFleetConnectionModal(id) {
      this.$refs.modal.openModal();
      this.modalType = "delete";
      this.modalTitle = "Delete Fleet Connection";
      this.modalDescription = "";
      this.selectedFleetConnection = id;
    },

    async deleteFleetConnection() {
      this.deleteFleetLoading = true;
      try {
        await this.$store.dispatch(
          "business/deleteFleetConnection",
          this.selectedFleetConnection
        );
      } catch (error) {
        this.toast("error", "Something went wrong");
      }
      this.deleteFleetLoading = false;
      this.closeModal();
    },

    filterChange() {
      this.init();
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
    fleetConnections() {
      return this.formatData(this.rawFleetConnections);
    },
  },
};
</script>
