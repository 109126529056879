<template>
  <div class="mb-16">
    <div class="loading centered" v-if="loading">
      <ui-spinner class="mx-auto"></ui-spinner>
    </div>
    <div v-if="!loading">
      <FormulateForm v-model="values" @submit="onSubmit">
        <div class="grid grid-cols-2 gap-4">
          <FormulateInput
            type="text"
            name="driverName"
            label="Full Name"
            validation="required"
          />
          <FormulateInput
            type="date"
            name="driverDateOfBirth"
            label="Date Of Birth"
            validation="required"
          />
        </div>
        <div class="grid grid-cols-2 gap-4">
          <FormulateInput
            type="text"
            name="driversLicenseNumber"
            label="Licence Number"
            validation="required"
          />
          <FormulateInput
            type="date"
            name="driversLicenseExpiry"
            label="Licence Expiry"
            validation="required"
          />
        </div>
        <FormulateInput
          type="select"
          name="driversLicenseState"
          label="Licence State"
          :options="states"
          validation="required"
        />
        <div class="grid grid-cols-2 gap-4">
          <FormulateInput
            type="select"
            name="driversBaseFatiguePlan"
            label="Base Fatigue Plan"
            :options="fatiguePlans"
            validation="required"
          />
          <FormulateInput
            type="select"
            name="driversBaseTimeZone"
            label="Base Time Zone"
            :options="timezones"
            validation="required"
          />
        </div>

        <div class="grid grid-cols-2 gap-4">
          <FormulateInput
            type="select"
            name="engineRegion"
            label="Region"
            :options="regions"
            validation="required"
            @change="setFatiguePlans(true)"
          />
          <FormulateInput
              type="vehicle-select"
              label="Default Vehicle"
              style="margin-bottom: 0px"
              class="content-center pb-4"
              :getOptionKey="(option) => option._id"
              :getOptionLabel="(option) => option.vehiclePlate"
              :filterable="false"
              :clearable="false"
              :isDriveVehicle="true"
              @input="selectVehicle($event)"
              :help="`Current Default Vehicle (${values.vehiclePlate})`"
              name="vehicleSelect"
              :value="model"
          />
        </div>

        <div class="items-center justify-between mt-4">
          <FormulateInput
            type="submit"
            input-class="w-full btn btn-primary"
            :disabled="submitLoading"
            :style="{ backgroundColor: theme.secondaryColor }"
          >
            <span v-if="submitLoading"
              ><font-awesome-icon icon="spinner" class="mr-1 loader" />
              Submitting..</span
            >
            <span v-if="!submitLoading">Submit</span>
          </FormulateInput>
        </div>
      </FormulateForm>
      <div class="mb-3 text-center text-red-500" v-if="errorMessage">
        {{ errorMessage }}
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import { formatDate } from "@/_helper";
import { STATES } from "@/constants/location";
import { TIMEZONE } from "@/constants/location";
import {
  NHVR_FATIGUE_PLAN,
  WAHVA_FATIGUE_PLAN,
} from "@/constants/fatigue-plan";
import { REGION } from "@/constants/region";

export default {
  props: {
    detailsId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      values: {},
      loading: false,
      submitLoading: false,
      hasErrorMessage: false,
      errorMessage: "",
      states: STATES,
      timezones: TIMEZONE,
      fatiguePlans: [],
      regions: REGION,
      model: {},
    };
  },
  async mounted() {
    this.loading = true;

    const driverDetails = await this.$store.dispatch(
      `driver/getDriverByID`,
      this.detailsId
    );
    if(driverDetails && driverDetails["vehicle"]) {
      this.model = driverDetails["vehicle"];
    }

    this.values = this.parseDriver(driverDetails);
    this.driverId = driverDetails._id;

    await this.setFatiguePlans();
    this.loading = false;
  },
  methods: {

    selectVehicle(data) {
      this.values.vehicle = data._id;
    },

    async setFatiguePlans(engineRegionChange = false) {
      let fatiguePlans =
        this.values.engineRegion === "WAHVA"
          ? WAHVA_FATIGUE_PLAN
          : NHVR_FATIGUE_PLAN;

      this.fatiguePlans = [];
      _.mapValues(fatiguePlans, (val, key) => {
        return this.fatiguePlans.push({
          value: val,
          label: key.replace(/_/g, " "),
        });
      });
      if (engineRegionChange) {
        this.values.driversBaseFatiguePlan = this.fatiguePlans[0].value;
      }
    },
    parseDriver(driver) {
      return {
        driversBaseLocation: driver.driversBaseLocation,
        driverDateOfBirth: formatDate(driver.driverDateOfBirth, "YYYY-MM-DD"),
        bfmNumber: driver.driversBFMNumber,
        driversLicenseNumber: driver.driversLicenseNumber,
        driversLicenseState: driver.driversLicenseState,
        driversBaseFatiguePlan: driver.driversBaseFatiguePlan,
        driversBaseTimeZone: driver.driversBaseTimeZone,
        driversLicenseExpiry: formatDate(
          driver.driversLicenseExpiry,
          "YYYY-MM-DD"
        ),
        driverName: driver.driverName,
        engineRegion: driver.engineRegion,
        vehicle: driver.vehicle || "N/A",
        vehiclePlate: driver?.vehicle?.vehiclePlate || "N/A",
      };
    },
    async onSubmit() {
      this.errorMessage = "";
      this.submitLoading = true;
      const me = this.$store.getters[`account/me`];
      const entityID = me.business._id;

      delete this.values.vehicleSelect;
      let payload = {
        ...this.values,
        entityId: entityID,
        businessId: entityID,
        driverId: this.driverId,
      };

      if (payload.vehicle == 'N/A') {
        delete payload.vehicle;
      }

      if (payload.vehiclePlate == 'N/A') {
        delete payload.vehiclePlate;
      }

      await this.$store
        .dispatch(`driver/updateNonEWDDriver`, payload)
        .then(() => {
          this.$emit("closeRefresh");
          this.$store.commit("setDialogNotify", true);
          this.$store.commit("setMessageNotify", {
            state: "success",
            message: "Non-EWD Driver successfully updated.",
          });
          this.submitLoading = false;
        })
        .catch((err) => {
          this.errorMessage = err.message;
          this.submitLoading = false;
        });
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
