
import { API } from '@/api/config';
import { ObjectToQueryString } from '@/_helper';

class Event {

    async setDriverTrialData(data) {
        return await API.ACCOUNTS.post(`/event/set-driver-trial-data/${data.driverId}`, data)
    }
    createManualEvents(data){
        return API.ACCOUNTS.post(`/event/create-manual-events`, data)
    }
    getEvent( id = null ) {
        if(id) {
            return API.ACCOUNTS.get(`/event/${id}`)
        } else {
            return API.ACCOUNTS.get(`/event`)
        }
    }
    async getEventsByStartTime(data) {
        return await API.ACCOUNTS.post(`/event/find-by-start-time`, data)
    }
    async getEventByDriver(driverId) {
        return await API.ACCOUNTS.get(`/event/find-by-driver-id/${driverId}`)
    }
    async getPreviousEvent(driverId, from, to) {
        return await API.ACCOUNTS.post(`/event/find-previous-event/${driverId}`, { from, to })
    }
    async getEventByDateRange(driverId, from, to, filter = null) {
        return await API.ACCOUNTS.post(`/event/find-by-date-range/${driverId}`, { from, to, filter })
    }
    async getManualEventByEntityId(entityId,  query = { skip: 0, limit: 10, filter: {}, from: '', to: '' }) {
        let filter = '';
        if(typeof query.filter === 'object') try{ filter = JSON.stringify(query.filter) }catch(e) { throw 'Invalid Filter object'}
        query.filter = filter;
        if(query) query = '?'+ObjectToQueryString(query);
        return await API.ACCOUNTS.get(`/event/find-manual-events-by-entity/${entityId}${query}`)
    }
    async getManualEventByDriverId(id) {
        return await API.ACCOUNTS.get(`/event/find-manual-events-by-driver-id/${id}`)
    }
    async getManualEventById(id) {
        return await API.ACCOUNTS.get(`/event/find-manual-events-by-id/${id}`)
    }
    async getEndOfDayByDateRange(driverId, from, to) {
        return await API.ACCOUNTS.post(`/end-of-day/find-by-date-range/${driverId}`, { from, to })
    }
    async getAnnotationByDateRange(driverId, from, to) {
        return await API.ACCOUNTS.post(`/annotation/find-by-date-range/${driverId}`, { from, to })
    }
    async deleteDriverEventAnnotations(driverId) {
        return await API.ACCOUNTS.delete(`/event/hard-delete-events-annotations/${driverId}`)
    }

    async findEventModifications(query) {
        let filter = "";
        if (typeof query.filter === "object") {
          try {
            filter = JSON.stringify(query.filter);
          } catch (e) {
            throw "Invalid Filter object";
          }
        }
        query.filter = filter;
        return API.ACCOUNTS.get(`/event/modification/all?${ObjectToQueryString(query)}`);
      }

    async findEventModificationById(id) {
        return API.ACCOUNTS.get(`/event/modification/${id}`)
    }

    async findFleetDriverTrips(driverId, payload) {
        return API.ACCOUNTS.post(`/event/fleet/driver/${driverId}`, payload);
    }
}

export default new Event