<template>
  <div>
    <div v-if="readUsers" class="h-full">
      <Tabs
        clickEvent
        @click="fetchTab"
        :activeTab="0"
        class="flex flex-col h-full"
      >
        <Tab title="Active" class="flex-1 flex flex-col">
          <Table
            :isLoading="isLoading"
            :tableHeader="tableHeader"
            :paginationSettings="paginationSettings"
            :data="usersData"
          >
            <template #tableFilters>
              <div class="flex flex-row mb-1 sm:mb-0">
                <div class="relative">
                  <select
                    v-model="recordsPerPage"
                    class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  >
                    <option :value="10">10</option>
                    <option :value="20">20</option>
                    <option :value="50">50</option>
                  </select>
                  <div
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                  >
                    <font-awesome-icon
                      icon="caret-down"
                      class="text-gray-400"
                    />
                  </div>
                </div>
                <div class="relative">
                  <select
                    v-model="selectedFilteredStatus"
                    @change="selectFilteredStatus"
                    class="appearance-none h-full border border-r-none rounded-r-none -backdrop-hue-rotate-15block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"
                  >
                    <option
                      v-for="(filterStatus, index) in filterStatuses"
                      :key="index"
                      :value="index"
                    >
                      {{ filterStatus }}
                    </option>
                  </select>
                  <div
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                  >
                    <font-awesome-icon
                      icon="caret-down"
                      class="text-gray-400"
                    />
                  </div>
                </div>
              </div>
              <div class="block relative">
                <span
                  class="h-full absolute inset-y-0 left-0 flex items-center pl-2"
                >
                  <font-awesome-icon icon="search" class="text-gray-400" />
                </span>
                <input
                  placeholder="Search"
                  class="h-[38px] appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                  @input="queryForKeywords($event.target.value)"
                />
              </div>
            </template>
            <template #default="{ data }">
              <td class="px-5 py-2 text-center relative">
                <Dropdown ref="dropdown">
                  <ul class="py-2 text-sm">
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                        @click.prevent="viewUser(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="eye" class="my-auto mr-2" />
                        View
                      </StyledIconedLink>
                    </li>
                    <li v-if="writeUsers">
                      <StyledIconedLink
                        class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                        @click.prevent="editUser(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="edit" class="my-auto mr-2" />
                        Edit
                      </StyledIconedLink>
                    </li>
                    <li v-if="writeUsers">
                      <StyledIconedLink
                        class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                        @click.prevent="resendInvite(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon
                          icon="paper-plane"
                          class="my-auto mr-2"
                        />
                        Resend Invite
                      </StyledIconedLink>
                    </li>
                    <li v-if="writeUsers">
                      <StyledIconedLink
                        class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                        @click.prevent="deleteUser(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="trash" class="my-auto mr-2" />
                        Delete
                      </StyledIconedLink>
                    </li>
                  </ul>
                </Dropdown>
                <!-- <a
              v-if="readUserRoleAuditTrail && data.uid != null"
              class="flex cursor-pointer"
              @click.prevent="viewAuditTrail(data.uid)"
              ><font-awesome-icon
                icon="history"
                class="my-auto mx-1.5 hover:text-orange-primary-primary"
            /></a> -->
              </td>
            </template>
            <template #callToActionTop>
              <button
                v-if="writeUsers"
                class="btn btn-primary mr-2"
                @click.prevent="navigateToSSOPage()"
                :style="{ backgroundColor: theme.secondaryColor }"
              >
                Single Sign On
              </button>
              <button
                v-if="writeUsers"
                class="btn btn-primary"
                @click.prevent="newUser()"
                :style="{ backgroundColor: theme.secondaryColor }"
              >
                Add User
              </button>
            </template>
          </Table>
        </Tab>
        <Tab
          v-if="isGeotabAccount"
          title="Geotab Unlinked Users"
          class="flex-1 flex flex-col"
        >
          <Table
            :isLoading="isLoading"
            :tableHeader="tableHeader"
            :paginationSettings="paginationSettings"
            :data="usersData"
            @onPageChange="onPageChange($event)"
          >
            <template #tableFilters>
              <div class="flex flex-row mb-1 sm:mb-0">
                <div class="relative">
                  <select
                    v-model="recordsPerPage"
                    class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  >
                    <option :value="10">10</option>
                    <option :value="20">20</option>
                    <option :value="50">50</option>
                  </select>
                  <div
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                  >
                    <font-awesome-icon
                      icon="caret-down"
                      class="text-gray-400"
                    />
                  </div>
                </div>
                <div class="relative">
                  <select
                    v-model="selectedFilteredStatus"
                    @change="selectFilteredStatus"
                    class="appearance-none h-full border border-r-none rounded-r-none -backdrop-hue-rotate-15block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"
                  >
                    <option
                      v-for="(filterStatus, index) in filterStatuses"
                      :key="index"
                      :value="index"
                    >
                      {{ filterStatus }}
                    </option>
                  </select>
                  <div
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                  >
                    <font-awesome-icon
                      icon="caret-down"
                      class="text-gray-400"
                    />
                  </div>
                </div>
              </div>
              <div class="block relative">
                <span
                  class="h-full absolute inset-y-0 left-0 flex items-center pl-2"
                >
                  <font-awesome-icon icon="search" class="text-gray-400" />
                </span>
                <input
                  placeholder="Search"
                  class="h-[38px] appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                  @input="queryForKeywords($event.target.value)"
                />
              </div>
            </template>
            <template #default="{ data }">
              <!-- <td class="px-5 py-2">
                  <StyledIconedLink
                    class="flex cursor-pointer mx-1.5"
                    @click.prevent="newUserGeotab(data.id)"
                    :iconHoverColor="theme.secondaryColor"
                    ><font-awesome-icon
                      icon="sign-in-alt"
                      class="my-auto mx-1.5"
                  /></StyledIconedLink>
                </div>
              </td> -->

              <td class="px-5 py-2 text-center relative">
                <Dropdown ref="dropdown">
                  <ul class="py-2 text-sm">
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                        @click.prevent="newUserGeotab(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon
                          icon="sign-in-alt"
                          class="my-auto mr-2"
                        />
                        Add User
                      </StyledIconedLink>
                    </li>
                  </ul>
                </Dropdown>
              </td>
            </template>
          </Table></Tab
        >
      </Tabs>
    </div>

    <div v-if="!readUsers && hasRole" class="text-center">
      <h1>Not authorized to view users. Please contact your {{ userType }}.</h1>
    </div>

    <div v-if="!hasRole" class="text-center">
      <h1>
        You are currently not associated with a role. Please contact support.
      </h1>
    </div>

    <Modal ref="modal" :title="modalTitle" size="3xl" :height="modalHeight">
      <div v-if="modalContent == 'new'">
        <div class="mb-16">
          <AddUser @closeRefresh="closeRefresh" />
        </div>
      </div>
      <div v-if="modalContent == 'new-user-geotab'">
        <div class="mb-16">
          <AddUser @closeRefresh="closeRefresh" :defaultData="geotabUserData" />
        </div>
      </div>
      <div v-if="modalContent == 'view'">
        <UserDetailsView @editUser="editUser" :detailsId="modalId" />
      </div>
      <div v-if="modalContent == 'edit'">
        <UpdateUser :detailsId="modalId" @closeRefresh="closeRefresh" />
      </div>
    </Modal>
  </div>
</template>

<script>
import { Modal, Table, Dropdown } from "@/components/commons";
import { AddUser, UpdateUser } from "@/components/forms/user/";
import UserDetailsView from "@/components/view/user/";
import { formatDate, formatPaginationSettings } from "@/_helper";
import _ from "lodash";
import { mapGetters } from "vuex";
import { StyledIconedLink } from "@/styled-components";
import Tabs from "@/components/commons/tab/Tabs";
import Tab from "@/components/commons/tab/Tab";

export default {
  name: "Users",
  components: {
    Modal,
    Dropdown,
    AddUser,
    UserDetailsView,
    Table,
    UpdateUser,
    StyledIconedLink,
    Tabs,
    Tab,
  },
  data() {
    return {
      modalContent: false,
      modalTitle: "",
      modalId: "",
      users: [],
      modalHeight: "80vh",
      tableHeader: [
        "Email",
        "Full Name",
        "Phone No.",
        "Created Date",
        "Authentication",
      ],
      tableData: [],
      recordsPerPage: 50,
      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 50,
        visiblePageItemCount: 3,
      },
      searchKeyword: "",
      selectedFilteredStatus: 0,
      filterStatuses: ["All", "Active", "Inactive"],
      readUsers: false,
      writeUsers: false,
      readSecurityConfig: false,
      readUserRoleAuditTrail: false,
      userType: null,
      hasRole: true,
      currentTab: "active",
      filter: {
        limit: 10,
      },
      isLoading: false,
      geotabUserData: null,
    };
  },
  async mounted() {
    this.isLoading = true;
    const me = this.$store.getters[`account/me`];
    let securityRoles = me?.securityRoles;
    if (securityRoles && securityRoles.length > 0) {
      this.userType = securityRoles[0].type;
      const securityAccessObj = await this.setSecurityAccess(securityRoles);
      this.readUsers = securityAccessObj.readUsers;
      this.writeUsers = securityAccessObj.writeUsers;
      this.readUserRoleAuditTrail = securityAccessObj.readUserRoleAuditTrail;
    } else {
      this.hasRole = false;
    }
    // if (this.hasRole) await this.init();
    this.isLoading = false;
  },
  methods: {
    async init(paged = 1) {
      this.isLoading = true;

      if (this.isGeotabAccount && this.currentTab === "geotabPendingUsers") {
        const query = {
          skip: paged * this.filter.limit - this.filter.limit,
          limit: this.filter.limit,
          search: this.searchKeyword,
          filter: {},
        };
        const geotabUsers = await this.$store.dispatch(
          `geotabUsers/getGeotabUsers`,
          {
            query,
          }
        );

        if (geotabUsers.success) {
          this.paginationSettings = formatPaginationSettings(
            geotabUsers?.metadata
          );

          this.users = geotabUsers.data.map((webProfileJob) => {
            return {
              webProfileJobId: webProfileJob._id,
              ...webProfileJob.data,
            };
          });
        } else {
          this.users = [];
        }
      } else {
        const proxySite = this.$store.getters[`account/proxySite`];
        const isProxySite = proxySite && !proxySite.isMainBusiness;
        const users = await this.$store.dispatch(`rolesandrespo/getUsers`, {
          ...(isProxySite ? { site: proxySite._id } : {}),
        });

        this.paginationSettings = {
          page: 1,
          totalPages: 1,
          totalRecords: users.length,
          visiblePageItemCount: 3,
        };
        this.users = this.formatUsers(users);
      }

      if (this.$refs["dropdown"]) this.$refs.dropdown.updateTableHeight();
      this.isLoading = false;
    },
    newUser: function () {
      this.$refs.modal.openModal();
      this.modalContent = "new";
      this.modalTitle = "Add User";
    },
    viewUser: function (id) {
      this.$refs.modal.openModal();
      this.modalContent = "view";
      this.modalTitle = "User Info";
      this.modalId = id;
    },
    editUser: function (id) {
      this.$refs.modal.openModal();
      this.modalContent = "edit";
      this.modalTitle = "User Edit";
      this.modalId = id;
    },
    async resendInvite(id) {
      if (confirm("Resend invite email to this user?")) {
        this.isLoading = true;
        await this.$store
          .dispatch("rolesandrespo/resendInvite", id)
          .then((res) => {
            if (res.statusCode > 400) {
              this.$store.commit("setMessageNotify", {
                state: "error",
                message: res.message,
              });
            } else {
              this.$store.commit("setMessageNotify", {
                state: "success",
                message: res.message,
              });
            }
            this.$store.commit("setDialogNotify", true);

            this.init();
          })
          .catch(() => {
            const errorMessage = `Something went wrong, please try again.`;
            this.$store.commit("setDialogNotify", true);
            this.$store.commit("setMessageNotify", {
              state: "error",
              message: errorMessage,
            });
          });
        this.isLoading = false;
      }
    },
    async deleteUser(id) {
      if (confirm("Are you sure you want to delete this user?")) {
        this.isLoading = true;
        await this.$store
          .dispatch("rolesandrespo/deleteBusinessUser", id)
          .then((res) => {
            if (res.statusCode > 400) {
              this.$store.commit("setMessageNotify", {
                state: "error",
                message: res.message,
              });
            } else {
              this.$store.commit("setMessageNotify", {
                state: "success",
                message: res.message,
              });
            }
            this.$store.commit("setDialogNotify", true);

            this.init();
          })
          .catch(() => {
            const errorMessage = `Something went wrong, please try again.`;
            this.$store.commit("setDialogNotify", true);
            this.$store.commit("setMessageNotify", {
              state: "error",
              message: errorMessage,
            });
            this.isLoading = false;
          });
      }
    },
    newUserGeotab(email) {
      this.$refs.modal.openModal();
      this.modalContent = "new-user-geotab";
      this.modalTitle = "Add Geotab User";
      const targetUser = this.users.find((user) => user.email === email);

      this.geotabUserData = targetUser;
    },
    viewAuditTrail: (uid) => {
      window.location.href = `/security-role/null/user/${uid}/audit-trail`;
    },
    closeModal: function () {
      this.$refs.modal.closeModal();
    },
    async closeRefresh() {
      this.$refs.modal.closeModal();
      await this.init();
    },
    formatUsers(users) {
      return users.map((user) => {
        return {
          id: user._id,
          email: user.email || "",
          userName: user.userName || "",
          phoneNumber: user.phoneNumber || "",
          createdAt: formatDate(user.createdAt),
          isRoot: user.isRoot,
          uid: user.uid || null,
          authOption: user.authOption || "basic",
        };
      });
    },
    queryForKeywords(value) {
      this.searchKeyword = value;
    },
    parseUsers(data) {
      if (this.currentTab === "geotabPendingUsers") {
        return data.map((userData) => [
          // email is unique for web profile job collection
          {
            id: userData.email,
            name: userData.email,
            itemType: "string",
          },
          {
            id: userData.email,
            name: userData.userName,
            itemType: "string",
          },
          {
            id: userData.email,
            name: userData.phoneNumber,
            itemType: "string",
          },
          {
            id: userData.email,
            name: "Pending",
            itemType: "string",
          },
          {
            id: userData.email,
            name: "basic", // Default
            itemType: "string",
          },
        ]);
      } else {
        return data.map((userData) => [
          {
            id: userData.id,
            name: userData.email,
            itemType: "string",
            uid: userData.uid,
          },
          {
            id: userData.id,
            name: userData.userName,
            itemType: "string",
            uid: userData.uid,
          },
          {
            id: userData.id,
            name: userData.phoneNumber,
            itemType: "string",
            uid: userData.uid,
          },
          {
            id: userData.id,
            name: userData.createdAt,
            itemType: "string",
            uid: userData.uid,
          },
          {
            id: userData.id,
            name: userData.authOption,
            itemType: "string",
            uid: userData.uid,
          },
        ]);
      }
    },
    selectFilteredStatus() {
      this.selectedStatus = this.filterStatuses[this.selectedFilteredStatus];
    },
    //TODO: set a proper naming convention and move to helper file??
    filterRow(keyword, attributeToFilter) {
      return keyword
        .toLowerCase()
        .split(" ")
        .every((v) => attributeToFilter.trim().toLowerCase().includes(v));
    },
    setSecurityAccess: (securityRoles) => {
      let initialReadUsers = false;
      let initialWriteUsers = false;
      let initialReadUserRoleAuditTrail = false;
      securityRoles.forEach((securityRole) => {
        const securityConfig = securityRole.securityConfig;
        if (securityConfig.permissions.readUsers.value) {
          initialReadUsers = securityConfig.permissions.readUsers.value;
        }
        if (securityConfig.permissions.writeUsers.value) {
          initialWriteUsers = securityConfig.permissions.writeUsers.value;
        }
        if (securityConfig.permissions.readUserRoleAuditTrail.value) {
          initialReadUserRoleAuditTrail =
            securityConfig.permissions.readUserRoleAuditTrail.value;
        }
      });
      return {
        readUsers: initialReadUsers,
        writeUsers: initialWriteUsers,
        readUserRoleAuditTrail: initialReadUserRoleAuditTrail,
      };
    },
    fetchTab(data) {
      switch (data) {
        case "Geotab Unlinked Users":
          this.currentTab = "geotabPendingUsers";
          break;
        case "Active":
          this.currentTab = "active";
          break;
      }
      this.init();
    },
    onPageChange(event) {
      if (event.page) {
        this.init(event.page);
      }
    },

    toast(state, msg) {
      const message = {
        state: state,
        message: msg,
      };
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", message);
    },
    navigateToSSOPage() {
      this.$router.push("/account/sso");
    },
  },
  computed: {
    usersData() {
      if (this.users) {
        let usersComputedData = this.users;
        if (this.selectedFilteredStatus) {
          usersComputedData = usersComputedData.filter((item) =>
            this.filterRow(this.selectedStatus, item.status)
          );
        }
        if (this.recordsPerPage) {
          usersComputedData = _.take(usersComputedData, this.recordsPerPage);
        }
        if (this.searchKeyword) {
          usersComputedData = usersComputedData.filter(
            (item) =>
              this.filterRow(this.searchKeyword, item.email) ||
              this.filterRow(this.searchKeyword, item.userName)
          );
        }
        if (!this.searchKeyword && !this.selectedStatus) {
          return this.parseUsers(this.users);
        }
        return this.parseUsers(usersComputedData);
      } else {
        return [];
      }
    },
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
    isGeotabAccount() {
      return this.$store.getters["account/getIsGeotabAccount"];
    },
  },
  watch: {
    isGeotabAccount(value) {
      if (value) {
        this.fetchTab("Geotab Unlinked Vehicles");
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
