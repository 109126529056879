/* eslint-disable */
import _ from "lodash";
import axios from "axios";
import { Business, DriverManagement, Event, Report } from "@/api";
import { findRootUID } from "@/_helper";
import { servicesList } from "@/api/services";

export default {
  // @Business
  async createBusiness({ commit, dispatch }, data) {
    if (!data || _.isEmpty(data)) throw "Invalid data object";
    try {
      const response = await Business.createBusiness(data);
      commit("setNewCreatedBusiness", response.data.data);
      if (!_.isEmpty(data["create_password"])) {
        // Create Business with Password
        const newPassword = await dispatch("createBusinessPassword", {
          id: response.data.data._id,
          password: data["create_password"],
        });
        return newPassword;
      } else {
        return response.data.data;
      }
    } catch (error) {
      throw error.response.data;
    }
  },

  async createBusinessWithContract({ commit }, data) {
    if (!data || _.isEmpty(data)) throw "Invalid data object";
    try {
      const response = await Business.createBusinessWithContract(data);
      commit("setNewCreatedBusiness", response.data.data);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async createBusinessPassword({ commit, state }, data) {
    if (!data.id) throw "Invalid Business id";
    if (!data.password) throw "Invalid data password";
    try {
      const response = await Business.patchBusinessCreatePassword(
        data.id,
        data.password
      );
      if (!_.isEmpty(state.newCreatedBusiness))
        commit("setNewCreatedBusiness", {
          ...state.newCreatedBusiness,
          password: response.data.data,
        });
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async updateBusiness({ state }, payload) {
    if (!payload || _.isEmpty(payload)) throw "Invalid data object" + state.me;
    try {
      const response = await Business.updateBusiness(
        payload.businessId,
        payload.data
      );
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async getBusiness({ state }, param) {
    try {
      const response = await Business.getBusiness(param);
      if (!response?.data?.data) return [];
      if (param.id) {
        response.data.data.uid = findRootUID(response.data.data);
        return response.data.data;
      } else {
        const data = response?.data?.data.map((data) => {
          data.uid = findRootUID(data);
          return data;
        });
        return { results: data, metadata: response.data.resultsMeta };
      }
    } catch (error) {
      console.log(state);
      console.log(error);
      throw error.response;
    }
  },
  async getBusinessByUID({ state }, uid) {
    if (!uid) throw "Invalid uid " + state.me;
    try {
      const response = await Business.getBusinessByUID(uid);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getBusinessBySiteId({ state }, siteId) {
    if (!siteId) throw "Invalid uid " + state.me;
    try {
      const response = await Business.getBusinessBySiteId(siteId);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async acceptBusiness({ state }, id) {
    if (!id) throw `Invalid id ${Number(state.me)}`;

    try {
      const response = await Business.acceptBusiness(id);
      return response.data.data;
    } catch (error) {
      throw error.response;
    }
  },

  async getTokenByBusiness({ state }, id) {
    if (!id) throw "Invalid id " + state.me;
    try {
      const response = await Business.getTokenByBusiness(id);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async generateToken({ state }, id) {
    if (!id) throw "Invalid id " + state.me;
    try {
      const response = await Business.generateToken(id);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async saveQuery({ state }, data) {
    if (!data || _.isEmpty(data)) throw "Invalid data object" + state.me;
    try {
      const response = await Business.saveQuery(data);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async getQueryByBusiness({ state }, payload) {
    if (!payload.businessId) throw "Invalid partnerID" + state.me;
    const params = {
      businessId: payload.businessId,
    };
    const pagination = payload.query;

    try {
      const response = await Business.getQuery(params, pagination);
      if (response.data?.resultsMeta) {
        return {
          results: response.data.data,
          metadata: response.data.resultsMeta,
        };
      } else {
        return response.data;
      }
    } catch (error) {
      throw error.response.data;
    }
  },

  async saveApiUsageDisableOption({ state }, data) {
    if (!data || _.isEmpty(data)) throw "Invalid data object" + state.me;
    try {
      const response = await Business.saveApiUsageDisableOption(data);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async getApiUsageDisableOption({ state }, payload) {
    if (!payload.businessId) throw "Invalid partnerID" + state.me;
    try {
      const response = await Business.getApiUsageDisableOption(
        payload.businessId
      );
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async getApiUsagePercentage({ state }, payload) {
    if (!payload.businessId) throw "Invalid partnerID" + state.me;
    try {
      const response = await Business.getApiUsagePercentage(payload.businessId);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  //@Driver Management
  async getContractByBusiness({ rootGetters }, param) {
    if (typeof param === "string")
      param = { uid: param, query: { skip: 0, limit: 999 } };
    if (!param.uid) throw "Invalid UID or ID";
    try {
      if (typeof param.query?.filter != "object") param.query.filter = {};

      if (!("siteId" in param.query.filter)) {
        const proxySite = rootGetters[`account/proxySite`];
        if (!_.isEmpty(proxySite) && _.isObject(proxySite))
          param.query.filter.siteId = proxySite._id;
      }

      const response = await DriverManagement.getContractByBusinessId(
        param.uid,
        param.query
      );
      if (response.data?.resultsMeta) {
        return {
          results: response.data.data,
          metadata: response.data.resultsMeta,
        };
      } else {
        return response.data.data;
      }
    } catch (error) {
      throw error.response.data;
    }
  },

  async getContractByDriver(_, param) {
    if (typeof param === "string")
      param = { uid: param, query: { skip: 0, limit: 999 } };
    if (!param.uid) throw "Invalid UID or ID";
    try {
      const response = await DriverManagement.getContractByDriverId(
        param.uid,
        param.query
      );
      if (response.data?.resultsMeta) {
        return {
          results: response.data.data,
          metadata: response.data.resultsMeta,
        };
      } else if (response.data?.data) {
        return response.data.data;
      } else {
        return response.data;
      }
    } catch (error) {
      throw error.response.data;
    }
  },
  async getVehiclesByBusiness({ state }, uid) {
    if (!uid) throw "Invalid uid " + state.me;
    try {
      const response = await DriverManagement.getVehiclesByBusiness(uid);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getDeviceLogByDateRange({ state }, data) {
    if (!data) throw "Invalid uid " + state.me;
    try {
      const response = await DriverManagement.getDeviceLogByDateRange(
        data.driverId,
        data.from,
        data.to
      );
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async createContract({ state }, data) {
    if (!data || _.isEmpty(data)) throw "Invalid data object" + state.me;
    try {
      const response = await DriverManagement.createContract(data);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async updateContract({ state }, data) {
    if (!data || _.isEmpty(data)) throw "Invalid data object" + state.me;
    try {
      let isApproval = data?.isApproval ?? true;
      const response = await DriverManagement.updateContract(
        data.data,
        isApproval
      );
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getContractById({ state }, uid) {
    if (!uid) throw "Invalid uid " + state.me;
    try {
      const response = await DriverManagement.getContract(uid);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async delinkContract({ state }, data) {
    if (!data) throw "Invalid driver/business id" + state.me;
    try {
      const response = await DriverManagement.delinkContract(
        data.driverId,
        data.businessId
      );
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async updateNonEWDContractStatus({ state }, data) {
    if (!data) throw "Invalid driver/business id" + state.me;
    try {
      const response = await DriverManagement.updateNonEWDContractStatus(data);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async resendInvite({ state }, contractId) {
    if (!contractId) throw "Invalid contract id" + state.me;
    try {
      const response = await DriverManagement.resendInvite(
        "business",
        contractId
      );
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async resendInviteByBusiness({ state }, businessId) {
    if (!businessId) throw "Invalid business id" + state.me;
    try {
      const response = await DriverManagement.resendInviteByBusiness(
        businessId
      );
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async rescindInvite({ state }, contractId) {
    if (!contractId) throw "Invalid contract id" + state.me;
    try {
      const response = await DriverManagement.rescindInvite(
        "business",
        contractId
      );
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  //@Event
  async setDriverTrialData({ state }, data) {
    if (!data) throw "Invalid uid " + state.me;
    try {
      const response = await Event.setDriverTrialData(data);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async createManualEvents({ state }, data) {
    if (!data) throw "Invalid uid " + state.me;
    try {
      const response = await Event.createManualEvents(data);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async getEventByDriver({ state }, uid) {
    if (!uid) throw "Invalid uid " + state.me;
    try {
      const response = await Event.getEventByDriver(uid);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async getEventByDateRange({ state }, data) {
    if (!data) throw "Invalid uid " + state.me;
    try {
      const response = await Event.getEventByDateRange(
        data.driverId,
        data.from,
        data.to,
        data?.filter
      );
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async getManualEventByEntityId({ state, rootGetters }, param) {
    if (!param.entityId) throw "Invalid entityId " + state.me;
    try {
      if (typeof param.query?.filter != "object") param.query.filter = {};

      const proxySite = rootGetters[`account/proxySite`];
      if (!_.isEmpty(proxySite) && _.isObject(proxySite))
        param.query.filter = { ...param.query.filter, siteId: proxySite._id };

      const response = await Event.getManualEventByEntityId(
        param.entityId,
        param.query
      );
      if (response.data?.resultsMeta) {
        return {
          results: response.data.data,
          metadata: response.data.resultsMeta,
          status: response.data.statusCode,
          message: response.data.message,
        };
      } else {
        return response.data.data;
      }
    } catch (error) {
      throw error.response.data;
    }
  },

  async getManualEventById({ state }, id) {
    if (!id) throw "Invalid id " + state.me;
    try {
      const response = await Event.getManualEventById(id);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async getPreviousEvent({ state }, data) {
    if (!data) throw "Invalid uid " + state.me;
    try {
      const response = await Event.getPreviousEvent(
        data.driverId,
        data.from,
        data.to
      );
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async getEndOfDayByDateRange({ state }, data) {
    if (!data) throw "Invalid uid " + state.me;
    try {
      const response = await Event.getEndOfDayByDateRange(
        data.driverId,
        data.from,
        data.to
      );
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async getAnnotationByDateRange({ state }, data) {
    if (!data) throw "Invalid uid " + state.me;
    try {
      const response = await Event.getAnnotationByDateRange(
        data.driverId,
        data.from,
        data.to
      );
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async deleteDriverEventAnnotations({ state }, driverId) {
    if (!driverId) throw "Invalid id " + state.me;
    try {
      const response = await Event.deleteDriverEventAnnotations(driverId);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  //@Report
  async downdloadReport({ state, rootGetters }, data) {
    if (!data) throw "Invalid driver id " + state.me;
    return new Promise((resolve, reject) => {
      const token = rootGetters[`account/accessToken`];
      axios
        .post(`${servicesList.REPORT.URL}report/download`, data, {
          headers: { Authorization: `Bearer ${token}` },
          responseType: "blob",
        })
        .then(
          (res) => {
            const filenameHeader =
              res.headers["content-disposition"].split(";")[1];
            const filename = filenameHeader
              .substring(filenameHeader.indexOf("=") + 1)
              .replace(/"/g, "");
            resolve({
              filename,
              pdf: res.data,
            });
          },
          (err) => reject(err.response.data)
        );
    });
  },

  async sendReport({ state }, data) {
    if (!data) throw "Invalid data " + state.me;
    return new Promise((resolve, reject) => {
      Report.sendReport(data).then(
        (res) => {
          if ([200, 201].includes(res.data.statusCode)) resolve(res.data);
          else reject(res.data);
        },
        (err) => reject(err.response.data)
      );
    });
  },

  async sendFormsReport({ state }, data) {
    if (!data) throw "Invalid data " + state.me;
    return new Promise((resolve, reject) => {
      Report.sendFormsReport(data).then(
        (res) => {
          if (res.data.statusCode === 200) resolve(res.data);
          else reject(res.data);
        },
        (err) => reject(err.response.data)
      );
    });
  },

  async sendCompliancePeriodReport({ state }, data) {
    if (!data) throw "Invalid data " + state.me;
    return new Promise((resolve, reject) => {
      Report.sendCompliancePeriodReport(data).then(
        (res) => {
          if (res.data.statusCode === 200 || res.data.statusCode === 201)
            resolve(res.data);
          else reject(res.data);
        },
        (err) => reject(err.response.data)
      );
    });
  },

  async sendVehicleReport({ state }, data) {
    if (!data) throw "Invalid data " + state.me;
    return new Promise((resolve, reject) => {
      Report.sendVehicleReport(data).then(
        (res) => {
          if (res.data.statusCode === 200) resolve(res.data);
          else reject(res.data);
        },
        (err) => reject(err.response.data)
      );
    });
  },

  async sendVehicleMassReport({ state }, data) {
    if (!data) throw "Invalid data " + state.me;
    try {
      await Report.sendVehicleMassReport(data);
    } catch (error) {
      throw error.response.data;
    }
  },

  async sendDriverMassReport({ state }, data) {
    if (!data) throw "Invalid data " + state.me;
    try {
      await Report.sendDriverMassReport(data);
    } catch (error) {
      throw error.response.data;
    }
  },

  async sendFleetMassReport({ state }, data) {
    if (!data) throw "Invalid data " + state.me;
    try {
      await Report.sendFleetMassReport(data);
    } catch (error) {
      throw error.response.data;
    }
  },

  async sendPreStartSummaryReport({ state }, data) {
    if (!data) throw "Invalid data " + state.me;
    return new Promise((resolve, reject) => {
      Report.sendPreStartSummaryReport(data).then(
        (res) => {
          if (res.data.statusCode === 200) resolve(res.data);
          else reject(res.data);
        },
        (err) => reject(err.response.data)
      );
    });
  },

  async sendFitToDriveSummaryReport({ state }, data) {
    if (!data) throw "Invalid data " + state.me;
    return new Promise((resolve, reject) => {
      Report.sendFitToDriveSummaryReport(data).then(
        (res) => {
          if (res.data.statusCode === 200) resolve(res.data);
          else reject(res.data);
        },
        (err) => reject(err.response.data)
      );
    });
  },

  async sendInvestigationSummaryReport({ state }, data) {
    if (!data) throw "Invalid data " + state.me;
    return new Promise((resolve, reject) => {
      Report.sendInvestigationSummaryReport(data).then(
        (res) => {
          if (res.data.statusCode === 200) resolve(res.data);
          else reject(res.data);
        },
        (err) => reject(err.response.data)
      );
    });
  },

  async sendPerformanceSummaryReport({ state }, data) {
    if (!data) throw "Invalid data " + state.me;
    return new Promise((resolve, reject) => {
      Report.sendPerformanceSummaryReport(data).then(
        (res) => {
          if (res.data.statusCode === 200) resolve(res.data);
          else reject(res.data);
        },
        (err) => reject(err.response.data)
      );
    });
  },

  async sendNonConformanceSummaryReport({ state }, data) {
    if (!data) throw "Invalid data " + state.me;
    return new Promise((resolve, reject) => {
      Report.sendNonConformanceSummaryReport(data).then(
        (res) => {
          if (res.data.statusCode === 200) resolve(res.data);
          else reject(res.data);
        },
        (err) => reject(err.response.data)
      );
    });
  },

  // start of depo
  async updateDepotName({ state }, data) {
    if (!data.id) throw "Invalid depot id";
    try {
      const id = data.id;
      delete data.id;
      const response = await Business.updateDepotName(id, data);
      return response.data.data;
    } catch (error) {
      throw error.response.data + state.me;
    }
  },

  // @Depot Management
  async getDepot({ state }, entityId) {
    if (!entityId) throw "Invalid entityId ID" + state.me;
    try {
      const response = await Business.getDepot(entityId);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async getSingleDepot({ state }, depotId) {
    if (!depotId) throw "Invalid Depot ID" + state.me;
    try {
      const response = await Business.getSingleDepot(depotId);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async getSites({ state }, data) {
    if (!data.depotId) throw "Invalid Depot ID" + state.me;
    try {
      const response = await Business.getSites(data.depotId, data.isDeleted);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async createDepot({ state }, data) {
    if (!data) throw "Invalid Depot Information" + state.me;
    try {
      const response = await Business.createDepot(data);
      return response;
    } catch (error) {
      throw error.response.data;
    }
  },

  async patchDepot({ state }, payload) {
    if (!payload) throw "Invalid Depot Information" + state.me;
    try {
      const response = await Business.patchDepot(payload);
      return response;
    } catch (error) {
      console.log("error:", error);
      throw error.response.data;
    }
  },

  async getDepotLinkage({ state }, parentBusinessId) {
    if (!parentBusinessId) throw "Invalid parent businessId " + state.me;
    try {
      const response = await Business.getDepotLinkage(parentBusinessId);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async moveSite({ state }, data) {
    if (!data) throw "Invalid Id" + state.me;
    try {
      const response = await Business.moveSite(data);
      return response;
    } catch (error) {
      console.log("error:", error);
      throw error;
    }
  },

  async deleteRegion({ state }, id) {
    if (!id) throw "Invalid Id" + state.me;
    try {
      const response = await Business.deleteRegion(id);
      return response;
    } catch (error) {
      console.log("error:", error);
      throw error;
    }
  },

  async createDepotLink({ state }, payload) {
    if (!payload) throw "Invalid Payload" + state.me;
    try {
      const response = await Business.createDepotLink(payload);
      return response;
    } catch (error) {
      console.log("error:", error);
      throw error;
    }
  },

  async moveSiteDriverVehicle({ state }, payload) {
    if (!payload) throw "Invalid Depot Information" + state.me;
    try {
      const response = await Business.moveSiteDriverVehicle(payload);
      return response;
    } catch (error) {
      console.log("error:", error);
      throw error.response.data;
    }
  },

  async depotLink({ state }, payload) {
    if (!payload) throw "Invalid Payload" + state.me;
    try {
      const response = await Business.createDepotLink(payload);
      return response;
    } catch (error) {
      console.log("error:", error);
      throw error;
    }
  },

  async getSingleDepotLinks({ state }, id) {
    if (!id) throw "Invalid Payload" + state.me;
    try {
      const response = await Business.getSingleDepotLinks(id);
      return response.data.data;
    } catch (error) {
      console.log("error:", error);
      throw error;
    }
  },
  // end of depot

  async updateLicenseStatus({ state }, payload) {
    if (!payload.businessId) throw "Invalid businessId value" + state.me;
    try {
      let { businessId, isLicensed, unlicensedStartDate } = payload;
      const targetFields = {
        isLicensed,
        unlicensedStartDate,
      };
      const body = JSON.parse(JSON.stringify(targetFields));
      const response = await Business.updateLicenseStatus(businessId, body);
      return response.data.data;
    } catch (error) {
      throw error.response.data + state.me;
    }
  },

  async getDemoOptions({ state }) {
    try {
      const response = await Business.getDemoOptions();
      return response.data.data;
    } catch (error) {
      throw error.response.data + state.me;
    }
  },

  async resendBusinessInvite({ state }, id) {
    if (!id) throw "Invalid id" + state.me;
    try {
      const data = {
        id: id,
      };
      const response = await Business.resendBusinessInvite(data);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async deleteBusiness({ state }, id) {
    if (!id) throw "Invalid id" + state.me;
    try {
      const response = await Business.deleteBusiness(id);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async updateBusinessParentConnection({ state }, data) {
    if (!data || _.isEmpty(data)) throw "Invalid data object" + state.me;
    try {
      const response = await Business.updateBusinessParentConnection(
        data.businessId,
        data
      );
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async createBusinessLead({ state }, payload) {
    if (!payload || _.isEmpty(payload)) throw "Invalid data object" + state.me;
    try {
      const response = await Business.createBusinessLead(payload);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getBusinessLead({ state }, params) {
    try {
      const response = await Business.getBusinessLead(params);
      return response.data;
    } catch (error) {
      throw error.response.data + state.me;
    }
  },
  async getLead({ state }, id) {
    try {
      const response = await Business.getLead(id);
      return response.data;
    } catch (error) {
      throw error.response.data + state.me;
    }
  },
  async deleteLead({ state }, id) {
    try {
      const response = await Business.deleteLead(id);
      return response.data;
    } catch (error) {
      throw error.response.data + state.me;
    }
  },
  async transferLead({ state }, data) {
    try {
      const response = await Business.transferLead(data);
      return response.data;
    } catch (error) {
      throw error.response.data + state.me;
    }
  },

  async convertLead({ state }, data) {
    try {
      const response = await Business.convertLead(data);
      return response.data.data;
    } catch (error) {
      throw error.response.data + state.me;
    }
  },

  async getMarketplaceItems({ state }, id) {
    if (!id) throw "Invalid Payload" + state.me;
    try {
      const response = await Business.getMarketplaceItems(id);
      return response.data.data;
    } catch (error) {
      console.log("error:", error);
      throw error;
    }
  },

  async getMarketplaceItem({ state }, id) {
    if (!id) throw "Invalid Payload" + state.me;
    try {
      const response = await Business.getMarketplaceItemByID(id);
      return response.data.data;
    } catch (error) {
      console.log("error:", error);
      throw error.response.data;
    }
  },

  async getContractModuleMaster({ state }) {
    try {
      const response = await Business.getContractModuleMaster();
      return response.data.data;
    } catch (error) {
      console.log("error:", error);
      throw error.response.data + state.me;
    }
  },

  async createContractModule({ state }, payload) {
    if (!payload) throw "Invalid Payload" + state.me;
    try {
      return await Business.createContractModule(payload);
    } catch (error) {
      console.log("error:", error);
      throw error;
    }
  },

  async deleteContractModule({ state }, id) {
    if (!id) throw "Invalid Payload" + state.me;
    try {
      const response = await Business.deleteContractModule(id);
      return response.data.data;
    } catch (error) {
      console.log("error:", error);
      throw error;
    }
  },

  async updateDriverEmail({ state }, payload) {
    if (!payload) throw "Invalid Payload" + state.me;
    try {
      const response = await Business.updateDriverEmail(payload);
      return response;
    } catch (error) {
      console.log("error:", error);
      throw error;
    }
  },

  async getDriverIdsByBusinessId({ state }, businessId) {
    if (!businessId) throw "Invalid businessId" + state.me;
    try {
      const response = await DriverManagement.getDriverIdsByBusinessId(
        businessId
      );
      return response.data.data;
    } catch (error) {
      console.log("error:", error);
      throw error;
    }
  },

  async createGeotabCredentials({}, payload) {
    try {
      const response = await Business.createGeotabCredentials(payload);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async findEventModifications({}, query) {
    try {
      const response = await Event.findEventModifications(query);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async findEventModificationById({}, id) {
    try {
      const response = await Event.findEventModificationById(id);
      return response.data.data;
    } catch (error) {
      throw error;
    }
  },

  async toggleStatsPolling({}) {
    try {
      const response = await Business.toggleStatsPolling();
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  //AFM Access
  async createAFMAccess({ commit }, payload) {
    if (!payload || _.isEmpty(payload)) throw "Invalid data Object";
    try {
      const response = await DriverManagement.createAFMAccess(payload);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async updateAFMAccess({ commit }, payload) {
    if (!payload || _.isEmpty(payload)) throw "Invalid data Object";
    try {
      const response = await DriverManagement.updateAFMAccess(payload);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async findBusinessAFMAccess({ state }, payload) {
    if (!payload.businessId) throw "Invalid Business ID" + state.me;

    const params = {
      businessId: payload.businessId,
    };

    const pagination = payload.query;

    try {
      const response = await DriverManagement.findBusinessAFMAccess(
        params,
        pagination
      );
      if (response.data?.resultsMeta) {
        return {
          results: response.data.data,
          metadata: response.data.resultsMeta,
        };
      } else {
        return response.data;
      }
    } catch (error) {
      throw error.response.data;
    }
  },

  async findAFMAccessById({ state }, payload) {
    if (!payload.id) throw "Invalid AFM ID" + state.me;

    try {
      const response = await DriverManagement.findAFMAccessById(payload.id);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async findAFMAccessByParam() {
    if (!payload) throw "Invalid data" + state.me;

    try {
      const response = await DriverManagement.findAFMAccessByParam(payload);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async createFleetConnection({}, payload) {
    try {
      const response = await Business.createFleetConnection(payload);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async updateFleetConnection({}, data) {
    const { fleetConnectionId, payload } = data;
    try {
      const response = await Business.updateFleetConnection(
        fleetConnectionId,
        payload
      );
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async updateFleetConnectionFunctions({}, data) {
    const { fleetConnectionId, payload } = data;
    try {
      const response = await Business.updateFleetConnectionFunctions(
        fleetConnectionId,
        payload
      );
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async deleteFleetConnection({}, fleetConnectionId) {
    try {
      const response = await Business.deleteFleetConnection(fleetConnectionId);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async getFleetConnections({}, payload) {
    const { businessId, pagination } = payload;
    try {
      const response = await Business.getFleetConnections(
        businessId,
        pagination
      );

      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async getFleetData({}, payload) {
    const { businessId, fleetConnectionId, dataType } = payload;
    try {
      const response = await Business.getFleetData(
        businessId,
        fleetConnectionId,
        dataType
      );

      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async updateBusinessAndAdminDetails({}, { businessId, data }) {
    try {
      const response = await Business.updateBusinessAndAdminDetails(
        businessId,
        data
      );
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async findFleetDriverTrips({}, { date, driverId, vehicles }) {
    try {
      const payload = {
        vehicles,
        date,
      };
      const response = await Event.findFleetDriverTrips(driverId, payload);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },
};
