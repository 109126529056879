<template>
  <div class="w-full min-h-full flex-row account-view">
    <div
      v-if="readForms && formSettingExist"
      class="w-full min-h-full flex-row items-left"
    >
      <FormulateForm
        @submit="simulateForm"
        v-model="activeSemulatedForm"
        class="flex w-full mt-5"
      >
        <aside class="w-80 h-full border-r">
          <div class="overflow-y-auto flex flex-col border-b">
            <p class="text-sm py-4 px-3" :style="{ color: theme.primaryColor }">
              Names
            </p>
          </div>
          <div v-if="masterForm" class="mt-2 overflow-y-auto h-96">
            <button
              class="text-left w-full flex px-1 py-3 border-b border-gray-200"
              :class="{ 'bg-green-100': activeFormId == masterForm._id }"
              type="button"
              :style="{
                color:
                  activeFormId == masterForm._id || onlyMaster
                    ? 'rgb(55 65 81)'
                    : theme.primaryColor,
              }"
              @click="setActiveForm(masterForm._id)"
            >
              <span
                v-if="!hasActivatedCustomForm"
                class="mt-1 rounded-xl text-green-900"
                title="Activated Form"
              >
                <font-awesome-icon
                  :icon="['fas', 'check']"
                  class="my-auto mx-1.5"
                  size="lg"
                ></font-awesome-icon>
              </span>
              <span v-else>
                <font-awesome-icon
                  v-if="activeFormId == masterForm._id"
                  :icon="['fas', 'folder-open']"
                  class="my-auto mx-1.5"
                  size="lg"
                >
                </font-awesome-icon>
                <font-awesome-icon
                  v-else
                  :icon="['fas', 'folder']"
                  class="my-auto mx-1.5"
                  size="lg"
                >
                </font-awesome-icon>
              </span>
              <small class="ml-2"
                >{{ masterForm.name }} - Default
                <sub v-if="!hasActivatedCustomForm" class="text-green-900"
                  >(Activated)</sub
                >
              </small>
            </button>
            <div
              class="mt-5 py-2 border-b"
              :style="{ color: theme.primaryColor }"
            >
              <small>My Templates</small>
            </div>
            <div>
              <div v-if="this.myForms.length > 0">
                <div v-for="(data, index) in computedForms" :key="index">
                  <button
                    v-if="!data.isMaster"
                    class="text-left w-full flex px-1 py-3 border-b border-gray-200"
                    :class="{ 'bg-green-100': activeFormId === data.id }"
                    type="button"
                    :style="{
                      color:
                        activeFormId === data.id
                          ? 'rgb(55 65 81)'
                          : theme.primaryColor,
                    }"
                    @click="setActiveForm(data.id)"
                  >
                    <span
                      v-if="data.isActivated"
                      class="mt-1 rounded-xl text-green-900"
                      title="Activated Form"
                    >
                      <font-awesome-icon
                        :icon="['fas', 'check']"
                        class="my-auto mx-1.5"
                        size="lg"
                      ></font-awesome-icon>
                    </span>
                    <span v-else>
                      <font-awesome-icon
                        v-if="activeFormId === data.id"
                        :icon="['fas', 'folder-open']"
                        class="my-auto mx-1.5"
                        size="lg"
                      >
                      </font-awesome-icon>
                      <font-awesome-icon
                        v-else
                        :icon="['fas', 'folder']"
                        class="my-auto mx-1.5"
                        size="lg"
                      >
                      </font-awesome-icon>
                    </span>
                    <small class="relative ml-1 pr-3"
                      >{{ data.name }}
                      <small
                        v-if="data.version"
                        class="bg-green-100 border-white text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-900"
                        >{{ data.version }}</small
                      >
<!--                      <sub-->
<!--                        v-if="data.siteId && data.siteId.includes(siteId)"-->
<!--                        class="text-green-900"-->
<!--                        >(Activated)</sub-->
<!--                      >-->
                      <sub v-if="data.isActivated" class="text-green-900"
                        >(Activated)</sub
                      >
                    </small>
                  </button>
                </div>
              </div>
              <div
                v-else
                class="w-full px-1 py-2 border-b border-gray-200 opacity-40"
                :style="{ color: theme.primaryColor }"
              >
                <small><em>No templates found</em></small>
              </div>
            </div>
          </div>
        </aside>
        <div class="w-full flex flex-col">
          <div class="flex justify-between items-center border-b">
            <p
              class="text-sm text-gray-700 p-4"
              :style="{ color: theme.primaryColor }"
            >
              Form Preview
            </p>
            <router-link
              :to="createFormTemplateLink()"
              class="btn btn-primary text-sm p-4 text-blue-300 mr-5"
              :style="{ backgroundColor: theme.secondaryColor }"
            >
              Create Form Template
            </router-link>
          </div>

          <div class="overflow-y-auto px-3" style="height: 669px">
            <div v-if="!formLoading">
              <div class="w-full" v-if="activeForm">
                <div
                  v-if="forms.filter((item) => item.id === activeForm.id)[0]"
                  class="bg-white shadow-md rounded px-5 mt-4 pt-5 pb-8 mb-4"
                >
                  <h6
                    class="font-medium text-center pb-3 leading-tight text-base mt-5 mb-3 text-blue-600"
                  >
                    {{ activeForm.name }}
                    <span v-if="activeForm.isActivated">(Activated)</span>
                    <span v-if="!hasActivatedCustomForm && activeForm.isMaster"
                      >(Activated)</span
                    >
                  </h6>

                  <section
                    v-for="(section, index) in activeForm.sections"
                    :key="index"
                    class="mb-12"
                  >
                    <div class="mt-1 pb-3">
                      <h6
                        class="text-large font-semibold text-left leading-tight inline-block align-middle"
                      >
                        {{ section.headline }}
                      </h6>
                      <span v-if="section.subHeadline"> - </span
                      ><small class="italic text-small inline-block pt-4">{{
                        section.subHeadline
                      }}</small>
                    </div>
                    <hr />

                    <div
                      class="mt-5"
                      v-for="(control, index) in section.controls"
                      :key="index"
                      :style="{ color: theme.primaryColor }"
                    >
                      <FieldContainer :component="control" />
                    </div>
                  </section>
                </div>
              </div>
            </div>
            <div
              v-else
              class="w-full text-center overflow-y-auto px-3 text-lg inset-0 flex flex-col justify-center items-center"
              style="height: 669px"
            >
              <font-awesome-icon
                icon="cog"
                spin
                size="3x"
                class="text-gray-400"
              />
              <h6 class="mt-4">Loading</h6>
            </div>

            <div
              v-if="!activeForm"
              class="bg-white-100 border-l-4 border-orange-500 text-orange-700 p-4"
              role="alert"
            >
              <p class="font-bold" :style="{ color: theme.primaryColor }">
                Empty
              </p>
              <p :style="{ color: theme.primaryColor }">Form is not found.</p>
            </div>
          </div>
        </div>
        <aside class="w-24 h-full border-l" selected-control="[object Object]">
          <div class="overflow-y-auto flex flex-col border-b">
            <p class="text-sm py-4 px-3" :style="{ color: theme.primaryColor }">
              Actions
            </p>
          </div>
          <div class="pl-5 pt-4">
            <ul v-if="activeForm?.isMaster">
              <li class="hover:text-orange-primary py-4 pl-1 cursor-pointer">
                <a
                  :href="`/business/fatigue-management/forms/${formType}/edit-master`"
                  class="flex cursor-pointer"
                  title="Edit Form"
                >
                  <font-awesome-icon
                    :icon="['fas', 'edit']"
                    class="my-auto mx-1.5"
                    size="lg"
                  >
                  </font-awesome-icon>
                </a>
              </li>
              <li class="hover:text-orange-primary py-4 pl-1">
                <div
                  v-if="
                    writeForms && !hasActivatedCustomForm && activeForm.isMaster
                  "
                  class="flex"
                >
                  <font-awesome-icon
                    :icon="['fas', 'toggle-on']"
                    class="my-auto mx-1.5 text-green-900"
                    size="lg"
                  >
                  </font-awesome-icon>
                </div>
                <button
                  @click.prevent="updateFormStatus(activeForm, true)"
                  v-if="
                    writeForms && hasActivatedCustomForm && activeForm.isMaster
                  "
                  title="Click to select as activated form"
                  class="flex cursor-pointer"
                >
                  <font-awesome-icon
                    :icon="['fas', 'toggle-off']"
                    class="my-auto mx-1.5"
                    size="lg"
                  >
                  </font-awesome-icon>
                </button>
              </li>
            </ul>
            <ul v-if="!activeForm?.isMaster">
              <li class="hover:text-orange-primary py-4 pl-1 cursor-pointer">
                <a
                  :href="`/business/fatigue-management/forms/${activeForm?.id}/edit`"
                  class="flex cursor-pointer"
                  title="Edit Form"
                >
                  <font-awesome-icon
                    :icon="['fas', 'edit']"
                    class="my-auto mx-1.5"
                    size="lg"
                  >
                  </font-awesome-icon>
                </a>
              </li>
              <li class="hover:text-orange-primary py-4 pl-1">
                <div v-if="writeForms && activeForm?.isActivated" class="flex">
                  <font-awesome-icon
                    :icon="['fas', 'toggle-on']"
                    class="my-auto mx-1.5 text-green-900"
                    size="lg"
                  >
                  </font-awesome-icon>
                </div>
                <button
                  @click.prevent="updateFormStatus(activeForm)"
                  v-if="writeForms && !activeForm?.isActivated"
                  title="Click to select as activated form"
                  class="flex cursor-pointer"
                >
                  <font-awesome-icon
                    :icon="['fas', 'toggle-off']"
                    class="my-auto mx-1.5"
                    size="lg"
                  >
                  </font-awesome-icon>
                </button>
              </li>
              <li v-if="isRootBusiness" class="hover:text-orange-primary py-4 pl-1 cursor-pointer">
                <button
                    class="flex cursor-pointer"
                    title="Clone Form"
                    @click.prevent="cloneForm()"
                >
                  <font-awesome-icon
                      :icon="['fas', 'clone']"
                      class="my-auto mx-1.5"
                      size="lg"
                  >
                  </font-awesome-icon>
                </button>
              </li>
            </ul>
          </div>
        </aside>
      </FormulateForm>
    </div>
    <div v-if="!readForms" class="w-full min-h-full text-center mt-5">
      <h1>
        Not authorized to view this forms. Please contact your business admin.
      </h1>
    </div>
    <div v-if="!formSettingExist">
      <div class="container mt-10">
        <header
          class="flex justify-between text-lg font-bold items-center py-3"
          :style="{ color: theme.primaryColor }"
        >
          Couldn't find any existing form setup for this site.
        </header>
        <button
          @click.prevent="createFormSetting()"
          class="btn mb-4 btn-primary w-full"
        >
          Create form setup
        </button>
      </div>
    </div>


    <Modal
        ref="modal"
        :title="modal.modalTitle"
        :size="modal.modalSize"
        :height="modal.modalHeight"
    >
      <div v-if="modal.modalContent === 'clone'">
        <div
            class="w-full text-sm font-bold"
            :style="{ color: theme.secondaryColor }"
        >
          Select a Depot site to clone this form
        </div>
        <div v-if="businessSites.length" class="mt-1 mb-2">
          <button
              v-for="(site, index) in businessSites"
              :key="index"
              class="w-full mt-1 text-sm text-blue-300 capitalize btn btn-primary"
              type="submit"
              @click="submitClone(site._id, site.name)"
              :style="{ backgroundColor: theme.secondaryColor }"
          >
            {{ site.name }}
          </button>
        </div>
        <p v-else class="my-2">
          <span class="text-sm">No business site to switch to.</span>
        </p>
      </div>
    </Modal>

  </div>
</template>
<script>
import { formatDate } from "@/_helper";
import { DEFAULT_MODAL_HEIGHT, FORM_TYPES } from "@/_helper/constants";
import _ from "lodash";
import FieldContainer from "@/views/builder/containers/FieldContainer.vue";
import { mapGetters } from "vuex";
import { Modal } from "@/components/commons";

export default {
  name: "FormDetails",
  components: { Modal, FieldContainer },
  props: { formType: { type: String, required: true } },
  data() {
    return {
      forms: [],
      entityId: "",
      isFormActivated: false,
      hasActivatedCustomForm: false,
      formSimulating: false,
      activeSemulatedForm: null,
      resSemForm: null,
      readForms: false,
      writeForms: false,
      formTypes: FORM_TYPES,
      activeForm: null,
      activeFormId: null,
      masterForm: null,
      onlyMaster: true,
      formLoading: true,
      myForms: [],
      businessId: "",
      siteId: "",
      isRootBusiness: true,
      formSettingExist: true,
      modal: {
        modalContent: false,
        modalTitle: '',
        modalId: '',
        modalHeight: DEFAULT_MODAL_HEIGHT,
      },
      businessSites: [],
    };
  },
  async mounted() {
    const me = this.$store.getters[`account/me`];
    let securityRoles = me?.securityRoles;
    if (securityRoles && securityRoles.length > 0) {
      const securityAccessObj = this.setSecurityAccess(securityRoles);
      this.readForms = securityAccessObj.readForms;
      this.writeForms = securityAccessObj.writeForms;
    }
    await this.init();
  },
  methods: {
    async init() {
      this.$emit("updateLoading", true);
      const me = this.$store.getters[`account/me`];
      // businessId is Main business ID and siteId is business SITE ID
      this.businessId = me?.business?._id;

      const proxySite = this.$store.getters[`account/proxySite`];
      const isProxySite =
        !_.isEmpty(proxySite) &&
        _.isObject(proxySite) &&
        !proxySite.isMainBusiness;
      this.isRootBusiness = !isProxySite;
      this.siteId = proxySite?._id;

      const nFormType = this.formTypes.filter(
        (item) => item === this.formType
      )[0];

      if (!nFormType) window.location.href = "/error-404";
      let masterForms =
        (await this.$store.dispatch(`form/getFormMastersByType`, nFormType)) ||
        [];
      let version, data;
      await this.setBusinessSites();

      if (this.isRootBusiness) {
        data = await this.$store.dispatch(
          "form/getFormSettingByBusinessId",
          this.businessId
        );
      } else {
        // If not main business, using businessId of site (me.businessId)
        data = await this.$store.dispatch(
          "form/getFormSettingByBusinessId",
          me.businessId
        );
      }

      if (data) {
        const ewdData = data?.EWD;
        const fatigueComplianceData = data?.fatigueCompliance;

        switch (nFormType) {
          case "prestart":
            version = ewdData?.prestart?.version;
            break;
          case "fitness_declaration":
            version = ewdData?.fitnessDeclaration?.version;
            break;
          case "incident":
            version = fatigueComplianceData?.incident?.version;
            break;
          case "hazard":
            version = fatigueComplianceData?.hazard?.version;
            break;
          case "defect":
            version = fatigueComplianceData?.defect?.version;
            break;
          case "infringement":
            version = fatigueComplianceData?.infringement?.version;
            break;
          default:
            version = "default";
            break;
        }
      } else {
        this.formSettingExist = false;
      }

      if (masterForms.length > 0) {
        const masterFormArr = masterForms.slice(0, 1);
        this.masterForm = masterFormArr[0];

        let payload = {
          formType: nFormType,
          businessId: this.businessId,
        };

        if (!this.isRootBusiness) {
          payload.siteId = this.siteId;
        }

        this.myForms =
          (await this.$store.dispatch(`form/getFormByType`, payload)) || [];

        if (this.myForms.length > 0) this.onlyMaster = false;
        const nForms = [...this.myForms, ...masterFormArr];

        if (version && version !== "default") {
          this.hasActivatedCustomForm = true;
        }

        if (nForms) {
          this.forms = this.formFormatItem(
            _.orderBy(nForms, ["createdAt"], ["desc"])
          );
          this.forms.map((form) => {
            form.isActivated = form.version === version;
          });

          if (this.forms.length) {
            this.activeForm = this.forms.filter((item) => {
              return item.isActivated || item.isMaster;
            })[0];

            if (this.activeForm) {
              this.activeFormId = this.activeForm?.id;
            }
          }
        }
      }
      this.formLoading = false;
      this.$emit("updateLoading", false);
    },
    setSecurityAccess(securityRoles) {
      let iReadForms = false;
      let iWriteForms = false;
      securityRoles.forEach((securityRole) => {
        const securityConfig = securityRole.securityConfig;
        if (securityConfig.forms.readForms?.value) {
          iReadForms = securityConfig.forms.readForms.value;
        }
        if (securityConfig.forms.writeForms?.value) {
          iWriteForms = securityConfig.forms.writeForms.value;
        }
      });
      return {
        readForms: iReadForms,
        writeForms: iWriteForms,
      };
    },
    formFormatItem(items) {
      let fItems = [];
      if (items) {
        items.map((item) => {
          const form = {
            id: item?._id,
            isActive: item?.isActive,
            name: item?.name,
            created: formatDate(item.createdAt),
            sections: item?.sections,
            parentId: item.parentId,
            isMaster: item?.isMaster,
            version: item?.version,
            isActivated: item?.isActivated,
            siteId: item?.siteId,
          };
          fItems.push(form);
        });
      }
      return fItems;
    },
    async updateFormStatus(data, isDefault = false) {
      this.formLoading = true;
      const me = this.$store.getters[`account/me`];
      const payload = {
        id: isDefault ? null : data.id,
        businessId: me?.businessId || me?.business?._id,
        formType: this.formType,
        isDefault: isDefault,
      };
      if (!this.isRootBusiness) {
        payload.siteId = this.siteId;
      }
      await this.$store
        .dispatch("form/updateFormStatus", payload)
        .then(() => {
          this.toast("success", "Form status successfully updated!");
          this.init();
        })
        .catch((err) => {
          this.toast("error", err.message);
        });
    },
    async simulateForm() {
      this.formSimulating = true;
      const form = this.activeSemulatedForm;
      await this.$store
        .dispatch("form/simulateForm", form)
        .then((res) => {
          this.resSemForm = res;
          this.toast("success", "Form successfully submitted!");
          this.formSimulating = false;
        })
        .catch(() => {
          this.toast("error", "Error required fields.");
          this.formSimulating = false;
        });
    },
    toast(state, message) {
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", { state, message });
    },
    createFormTemplateLink() {
      return `/business/fatigue-management/forms/new-template/${this.formType}`;
    },
    setActiveForm(formId) {
      this.formLoading = true;
      this.activeForm = this.forms.filter((item) => item.id === formId)[0];
      this.activeFormId = this.activeForm.id;
      setTimeout(() => {
        this.formLoading = false;
      }, 300);
    },
    async createFormSetting() {
      this.$emit("updateLoading", true);

      const payload = {
        siteId: this.siteId,
        businessId: this.businessId,
      };

      await this.$store
        .dispatch("form/createSiteFormSetting", payload)
        .then(async (data) => {
          this.$store.commit("setDialogNotify", true);
          this.$store.commit("setMessageNotify", {
            state: "success",
            message: data.message,
          });

          await this.init();
        })
        .catch((err) => {
          this.$store.commit("setDialogNotify", true);
          this.$store.commit("setMessageNotify", {
            state: "error",
            message: err.message,
          });
          this.$emit("updateLoading", false);
        });
    },

    cloneForm() {
      this.$refs.modal.openModal();
      this.modal.modalContent = 'clone';
      this.modal.modalTitle = 'Clone Form to Depot';
    },

    async submitClone(siteId, name) {
      const { value: answer } = await this.$swal.fire({
        title: `Clone Form to ${name}`,
        text: `Are you sure you want to clone this form to the Business site ${name}?
         Please note, the form will not be automatically activated; you will need to select it manually.`,
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, clone it now!",
        cancelButtonText: "Cancel",
      });
      if (answer) {
        const payload = {
          id: this.activeFormId,
          siteId: siteId,
          businessId: this.businessId,
        };

        await this.$store
            .dispatch("form/cloneFormToSite", payload)
            .then(async () => {
              this.$store.commit("setDialogNotify", true);
              this.$store.commit("setMessageNotify", {
                state: "success",
                message: "Form cloned successfully!",
              });

              await this.init();
            })
            .catch((err) => {
              this.$store.commit("setDialogNotify", true);
              this.$store.commit("setMessageNotify", {
                state: "error",
                message: err.message,
              });
              this.$emit("updateLoading", false);
            });
      }
    },

    async setBusinessSites() {
      const me = await this.$store.getters["account/me"];
      const proxySite = this.$store.getters["account/proxySite"];

      if (me.role.name === "business") {
        this.depots = await this.$store.dispatch(`business/getSites`, {
          depotId: me?.business?._id,
          isDeleted: false,
        });

        let sites = me?.webProfile?.site
            ? me?.webProfile?.depotSites
            : this.depots;

        this.businessSites = sites.filter((item) => {
          if (
              _.isEmpty(proxySite) ||
              (!_.isEmpty(proxySite) && item._id !== proxySite._id)
          ) {

            return item;
          }
        });
      }
    },

  },
  computed: {
    computedForms() {
      return this.forms ? this.forms : [];
    },
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
